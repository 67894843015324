export class ProductWarning {
  id: number;
  title: string;
  message: string;
  args: { name: string; default: string }[];
  type: 'description' | 'warning' | 'prop65';

  // this comes from an association via ProductGroups
  warningArgs?: any[];

  /**
   * Pass data to initialize value or nothing for new/default value
   */
  constructor(data?: Partial<ProductWarning>) {
    Object.assign(this, data);

    this.id = this.id || null;
    this.title = this.title || null;
    this.message = this.message || null;
    this.args = this.args || [];
    this.type = this.type || 'warning';
  }
}
