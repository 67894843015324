import { Image } from './image.model';
import { ProductGroup } from './product-group.model';

export class ProductBrand {
  id: number;
  brandName: string;
  wooId: number;
  image: Image;
  featuredProducts: ProductGroup[];
  newProducts: ProductGroup[];

  constructor(data: any) {
    Object.assign(this, data);

    this.featuredProducts = this.featuredProducts || [];
    this.newProducts = this.newProducts || [];
  }
}
