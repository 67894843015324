import { Image } from './image.model';
import { ProductChild } from './product-child.model';

export interface Fineline {
  fineline: string;
  deliveryAvailable: number;
  showOnKiosk: boolean;
  requiresFraudCheck: boolean;
  taxCode: string;
  products?: ProductChild[];
}

export interface ProductCategory {
  id: number;
  name: string;
  parentId: number;
  image: Image;
  wooId: number;
  isFeatured: boolean;
  hasDateRange?: boolean;
  startDate?: string;
  endDate?: string;
  googleTaxonomy: string;
  parent?: ProductCategory;
  children?: ProductCategory[];
  finelines?: Fineline[];
}
