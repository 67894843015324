import gql from 'graphql-tag';

export const GET_S3_OBJECT = gql`
  query GetS3Object($bucket: String!, $key: String!) {
    getS3Object(bucket: $bucket, key: $key)
  }
`;

export const GET_S3_BUCKET = gql`
  query GetS3Bucket($bucket: String!, $key: String!) {
    getS3Bucket(bucket: $bucket, key: $key)
  }
`;

export const UPLOAD_S3_OBJECT = gql`
  mutation UploadImage($params: S3ParamsInput!) {
    uploadImage(params: $params)
  }
`;

export const DELETE_S3_OBJECT = gql`
  mutation DeleteImage($bucket: String!, $key: String!) {
    deleteImage(bucket: $bucket, key: $key)
  }
`;

export interface GetS3Object {
  getS3Object: string;
}

export interface GetS3Bucket {
  getS3Bucket: string[];
}

export interface UploadImage {
  uploadImage: string;
}

export interface DeleteImage {
  deleteImage: string;
}
