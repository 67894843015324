import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) {}

  private checkUserIsAuthenticated = () => {
    return this.authService.state$.pipe(
      map(state => {
        const isAuthenticated = state.isAuthenticated();

        // If we are authenticated, navigate to our defaultRoute
        if (isAuthenticated) {
          this.location.replaceState(state.defaultRoute ?? 'dashboard');
          this.router.navigate([state.defaultRoute ?? 'dashboard']);
        }

        return !isAuthenticated; // We can go to the auth page if we are not authenticated
      }),
      tap(isAuthenticated => (isAuthenticated ? null : null))
    );
  };

  canActivate() {
    return this.checkUserIsAuthenticated();
  }

  canActivateChild() {
    return this.checkUserIsAuthenticated();
  }

  canLoad() {
    return this.checkUserIsAuthenticated();
  }
}
