import { Order } from './order.model';
import { OrderItem } from './order-item.model';
import { OrderDelivery } from './order-delivery.model';
import { OrderNote } from './order-note.model';

export class OrderChild {
  id: number;
  orderId: number;
  orderNumber: string;
  orderChildStatus: string;
  fulfillmentMethod: string;
  storeNumber: number;
  orderMeta: any;
  notes: OrderNote[];
  order: Order;
  orderItems: OrderItem[];
  deliveries: OrderDelivery[];
  processingAt: number;
  processedAt: number;
  completedAt: number;
  createdAt: string;
  updatedAt: string;

  constructor(data: any) {
    Object.assign(this, data);

    this.orderMeta = this.orderMeta || {};
    this.notes = this.notes || [];
    this.orderItems = this.orderItems || [];
    this.deliveries = this.deliveries || [];
  }
}
