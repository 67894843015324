import gql from 'graphql-tag';
import { ProductBrand } from '../models';

export const GET_PRODUCT_BRANDS = gql`
  {
    productBrands {
      id
      brandName
      wooId
      image
      featuredProducts {
        groupId
        title
        woosync {
          wooId
        }
      }
      newProducts {
        groupId
        title
        woosync {
          wooId
        }
      }
    }
  }
`;

export const UPDATE_PRODUCT_BRAND = gql`
  mutation UpdateProductBrand($productBrand: ProductBrandInput) {
    updateProductBrand(productBrand: $productBrand) {
      id
      brandName
      wooId
      image
      featuredProducts {
        groupId
        title
        woosync {
          wooId
        }
      }
      newProducts {
        groupId
        title
        woosync {
          wooId
        }
      }
    }
  }
`;

export const DELETE_PRODUCT_BRAND = gql`
  mutation DeleteProductBrand($id: Int!) {
    deleteProductBrand(id: $id)
  }
`;

export interface ProductBrandsQuery {
  productBrands: ProductBrand[];
}
