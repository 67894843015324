export interface QuantityBreakCode {
    code: number;
    type: string;
    note: string;
    qty1: number;
    break1: number;
    qty2: number;
    break2: number;
    qty3: number;
    break3: number;
    qty4: number;
    break4: number;
    qty5: number;
    break5: number;
}
