import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

/**
 * This is for the old missionControl route. It assumes the first
 * position is missionControl and maps the url segment to
 * mission-control
 */
@Injectable({ providedIn: 'root' })
export class RedirectGuard {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean {
    if (route.url.length > 0) {
      const storeId = route.url.length > 1 ? route.url[1].path : null;

      this.router.navigate(['mission-control'], { queryParams: { storeId } });
      return true;
    }

    this.router.navigateByUrl('/dashboard');
    return true;
  }
}
