import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgFallbackGuard, NoAuthGuard } from '@app/core';
import { RedirectGuard } from './core/guards/redirect.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./layouts/content-layout/content-layout.module').then(
        (m) => m.ContentLayoutModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canLoad: [NoAuthGuard],
    canActivate: [NoAuthGuard],
  },
  {
    path: 'mission-control',
    loadChildren: () =>
      import('@app/modules/mission-control/mission-control.module').then(
        (m) => m.MissionControlModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@app/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'missionControl',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
  },
  {
    path: 'missionControl/:storeId',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
  },
  {
    path: '',
    canActivate: [NgFallbackGuard],
    component: NgFallbackGuard,
  },
  {
    path: '**',
    canActivate: [NgFallbackGuard],
    component: NgFallbackGuard,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
