import { Injectable } from '@angular/core';
import { parse, unparse } from 'papaparse';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  PRODUCT_COLUMN_SORT = [
    { name: 'groupId', priority: 1 },
    { name: 'title', priority: 2 },
    { name: 'description', priority: 3 },
    { name: 'brand', priority: 4 },
    { name: 'species', priority: 5 },
    { name: 'hidePricingOnline', priority: 6 },
    { name: 'notSoldOnline', priority: 7 },
    { name: 'pickupOnly', priority: 8 },
    { name: 'deliveryAvailable', priority: 9 },
    { name: 'featured', priority: 10 },
    { name: 'Highlight', priority: 11 },
    { name: 'keywords', priority: 12 },
    { name: 'publish', priority: 13 },
  ];

  constructor() {}

  parseCSV(data: string) {
    return parse(data, {
      header: true,
    });
  }

  generateCSV(data: any, filename: string) {
    if (!data || data.length === 0) {
      return;
    }

    // because we have a variable number of HIGHLIGHT and CHILDREN columns, we need to
    // build a columns array that consists of them all.
    const columns: Set<string> = new Set();
    for (const row of data) {
      for (const key of Object.keys(row)) {
        columns.add(key);
      }
    }

    // sort the columns into a sensible order
    const sortedColumns = [...columns];
    sortedColumns.sort((a, b) => this.columnSort(a, b));

    const csv = unparse(data, { columns: sortedColumns });

    const blob = new Blob([csv], { type: 'text/csv;chatset=utf8l' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('visibility', 'show');
    link.download = filename + '.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  columnSort(a, b) {
    // lookup in our priority array
    const aPriority = this.PRODUCT_COLUMN_SORT.find(
      p => a.match(p.name) !== null
    );
    const bPriority = this.PRODUCT_COLUMN_SORT.find(
      p => b.match(p.name) !== null
    );

    if (aPriority && bPriority) {
      // if both exist in our priority, then give order based on priority
      return aPriority.priority - bPriority.priority;
    } else if (bPriority) {
      // if only B exists, then shift A to the back
      return 1;
    } else if (a.match(/\d+\s/) !== null && b.match(/\d+\s/) !== null) {
      // Matches a field starting with a number (specifically SKU/Size/Color fields)
      const aSplit = a.split(' ');
      const bSplit = b.split(' ');

      if (aSplit[0] === bSplit[0]) {
        // The number is the same, so sort by the word
        if (aSplit[1] === 'SKU') {
          return -1;
        } else if (aSplit[1] === 'Size') {
          return 1;
        } else {
          return 0;
        }
      } else {
        // the numbers are different, sort by number
        return parseInt(aSplit[0]) - parseInt(bSplit[0]);
      }
    } else {
      // all else fails, just compare the strings
      return a.localeCompare(b);
    }
  }
}
