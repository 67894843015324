import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';

import { OrderChild } from '../models';
import {
  GET_DELIVERIES,
  GET_DELIVERY_ORDER,
  GET_DELIVERY_ORDERS_LIST,
  OrderChildQuery,
  OrderChildrenQuery,
  OrderDeliveriesQuery,
  UPDATE_DELIVERY_ORDER,
  UpdateOrderChildQuery,
} from '../queries/order/order.graphql';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class OrderDeliveryService implements OnDestroy {
  searchTerm: string;
  filters: any;

  protected onDestroy = new Subject<void>();

  constructor(private apollo: Apollo, private logger: LoggerService) {}

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * DELIVERY ORDERS
   */

  getDeliveryOrders(search: string, filters: any = {}) {
    this.searchTerm = search;
    this.filters = filters;

    const mappedFilters: any = {};
    // eslint-disable-next-line guard-for-in
    for (const name in filters) {
      if (name === 'Store' && !filters[name].includes('all')) {
        mappedFilters.storeNumbers = filters[name];
      } else if (name === 'Status') {
        mappedFilters.orderChildStatus = filters[name];
      }
    }

    return this.apollo
      .watchQuery<OrderChildrenQuery>({
        query: GET_DELIVERY_ORDERS_LIST,
        variables: {
          search,
          ...mappedFilters,
        },
        fetchPolicy: 'network-only',
        pollInterval: 5000,
      })
      .valueChanges.pipe(
        takeUntil(this.onDestroy),
        tap(result =>
          this.logger.debug(
            'OrderDeliveryService',
            'New data for Delivery list',
            result
          )
        ),
        catchError(err => of([])),
        map((result: any) => (result.data ? result.data.orderChildren : []))
      );
  }

  getDeliveryOrder(id: number) {
    return this.apollo
      .watchQuery<OrderChildQuery>({
        query: GET_DELIVERY_ORDER,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
        pollInterval: 5000,
      })
      .valueChanges.pipe(map(r => new OrderChild(r.data.orderChild)));
  }

  updateDeliveryOrder(orderChild: OrderChild) {
    return this.apollo
      .mutate<UpdateOrderChildQuery>({
        mutation: UPDATE_DELIVERY_ORDER,
        variables: {
          orderChild,
        },
      })
      .pipe(map(r => new OrderChild(r.data.updateOrderChild)));
  }

  /**
   * DELIVERIES
   */

  getDeliveries(variables: {
    startDate: number;
    endDate: number;
    storeNumber: number;
  }) {
    return this.apollo
      .watchQuery<OrderDeliveriesQuery>({
        query: GET_DELIVERIES,
        variables,
        fetchPolicy: 'network-only',
        pollInterval: 5000,
      })
      .valueChanges.pipe(map(r => r.data.orderDeliveries));
  }
}
