import gql from 'graphql-tag';
import { Option as OptionV2 } from 'wilco-lib-models';
import { Option } from '../models';

export const GET_OPTION = gql`
  query Option($optionKey: String) {
    option(optionKey: $optionKey) {
      id
      optionKey
      optionValue
    }
  }
`;

export const GET_OPTIONS = gql`
  query Options($optionKeys: [String]) {
    options(optionKeys: $optionKeys) {
      id
      optionKey
      optionValue
    }
  }
`;

export const GET_OPTIONS_V2 = gql`
  query GetOptions($keys: [String!]!) {
    getOptions(keys: $keys) {
      id
      key
      value
    }
  }
`;

export const UPDATE_OPTION = gql`
  mutation UpdateOption($option: OptionInput) {
    updateOption(option: $option) {
      id
      optionKey
      optionValue
    }
  }
`;

export const UPDATE_OPTIONS = gql`
  mutation UpdateOptions($options: [OptionInput]) {
    updateOptions(options: $options) {
      id
      optionKey
      optionValue
    }
  }
`;

export interface OptionQuery {
  option: Option;
}

export interface OptionsQuery {
  options: Option[];
}
export interface IGetOptions {
  getOptions: OptionV2[];
}
