import gql from 'graphql-tag';
import { Order } from 'wilco-lib-models';
import {
  ExportOrder,
  PaginatedPackage,
  PaginatedRefund,
} from 'wilco-lib-models/src/domain/orders';
import { OrderChild, OrderDelivery } from '../../models';

const BASE_ORDER_FIELDS = `
    id
    externalId
    orderNumber
    customerFirstName
    customerLastName
    customerPhone
    customerEmail
    customerAccountNumber
    billingAddress 
    shippingAddress
    status
    orderDate
    integratedAt
    createdAt
    completedAt
`;

const ORDER_FIELDS = `
    ${BASE_ORDER_FIELDS}
    antiFraud
    giftcards
    couponTotal
    isCouponAccomodated
    approvedBy
    approvedAt
    packages {
      id
      status
      storeNumber
      packageNumber
      fulfillmentMethod
      processedAt
      createdAt
      meta
      pickupAfter
      _orderId
      shippingCharge {
        id
        total
        service
        externalId
      }
      order {
        id
        externalId
        customerFirstName
        customerLastName
        couponTotal
      }
      fulfillments {
        id
        status
        stagedAt
        picker
        processingAt
        notifiedAt
        _packageId
      }
      store {
        id
        name
        address
        phoneNumber
      }
      orderItems {
        id
        _packageId
        externalId
        itemNumber
        quantity
        quantityFilled
        quantityRefunded
        stagedAt
        unitPrice
        total
        tax
        refundTotal
        item {
          masterData
          locationSecondaryData
          image
          product {
            images
          }
        }
      }
      refunds {
        id
        _packageId
        externalId
        status
        total
        reason
        error
        refundPayment
        refundAfter
        initiatedBy
        approvedBy
        shippingItems
        lineItems
        approvedAt
        completedAt
        transaction {
          id
          salesHeader
        }
      }
      transaction {
        id
        salesHeader
      }
    }
`;

export const GET_ORDERS_LIST = gql`
  query Orders($params: JSON) {
    orders(params: $params) {
      ${BASE_ORDER_FIELDS}
    }
  }
`;
export const GET_ORDERS = gql`
  query Orders($params: JSON) {
    orders(params: $params) {
      ${ORDER_FIELDS}
    }
  }
`;

export const GET_ORDER = gql`
  query Order($id: Int!, $storeNumber: Int) {
    order(id: $id, storeNumber: $storeNumber) {
      ${ORDER_FIELDS}
    }
  }
`;

export const GET_EXPORT_ORDERS = gql`
  query GetExportOrders($ids: [Int!]!) {
    getExportOrders(ids: $ids) {
      orderNumber
      customerName
      orderDate
      orderTotal
      docId
    }
  }
`;

export const GET_UNASSOCIATED_PACKAGES = gql`
  query GetUnassociatedPackages($pageNumber: Int!, $size: Int!) {
    getUnassociatedPackages(pageNumber: $pageNumber, size: $size) {
      total
      results {
        id
        packageNumber
        order {
          id
          integratedAt
        }
      }
    }
  }
`;

export const GET_UNASSOCIATED_REFUNDS = gql`
  query GetUnassociatedRefunds($pageNumber: Int!, $size: Int!) {
    getUnassociatedRefunds(pageNumber: $pageNumber, size: $size) {
      total
      results {
        id
        integratedAt
        package {
          id
          packageNumber
        }
      }
    }
  }
`;

// export const SYNC_ORDER = gql`
//   mutation syncOrder($id: Int!) {
//     syncOrder(id: $id)
//   }
// `;

// export const SYNC_ORDERS = gql`
//   mutation syncOrders {
//     syncOrders
//   }
// `;

// export const SYNC_ECOM_ORDERS = gql`
//   mutation syncEcomOrders {
//     syncEcomOrders
//   }
// `;

// export const SYNC_LEGACY_STATUS = gql`
//   mutation syncLegacyStatus {
//     syncLegacyStatus
//   }
// `;

export const UPDATE_ORDER_V2 = gql`
  mutation UpdateOrder($order: OrderInput!, $noDelete: Boolean) {
    updateOrder(order: $order, noDelete: $noDelete)
  }
`;

export const APPROVE_ORDER = gql`
  mutation ApproveOrder($id: Int!, $approvedBy: String!, $rules: JSON) {
    approveOrder(id: $id, approvedBy: $approvedBy, rules: $rules)
  }
`;

export const INTEGRATE_ORDER = gql`
  mutation IntegrateOrder($id: Int!) {
    integrateOrder(id: $id)
  }
`;

export const INTEGRATE_FAILED_ORDERS = gql`
  mutation IntegrateFailedOrders {
    integrateFailedOrders
  }
`;

// Deprecated Queries
export const ORDER_FIELDS_DEP = `
    id,
    orderNumber,
    externalId,
    externalSource,
    orderDate,
    orderStatus,
    customerFirstName,
    customerLastName,
    customerAccountNumber,
    customerPhone,
    customerEmail,
    customerIp,
    billingStreetAddress1,
    billingStreetAddress2,
    billingCity,
    billingState,
    billingZip,
    shippingStreetAddress1,
    shippingStreetAddress2,
    shippingCity,
    shippingState,
    shippingZip,
    poNumber,
    paymentMethod,
    paymentTransactionId,
    orderTax,
    orderTotal,
    savingsTotal,
    couponTotal,
    couponTax,
    antiFraud,
    refunds,
    notes,
    completedAt,
    createdAt,
    updatedAt
`;

export const ORDER_CHILD_FIELDS = `
    id,
    orderId,
    orderNumber,
    orderChildStatus,
    fulfillmentMethod,
    storeNumber,
    orderMeta,
    notes,
    processingAt,
    processedAt,
    completedAt,
    createdAt,
    updatedAt
`;

const ORDER_DELIVERY_FIELDS = `
    id,
    orderChildId,
    truckId,
    deliveryStatus,
    deliveryDateTime,
    unloadTime,
    capacityRequired,
    orderItems,
    loadedAt,
    approvedAt,
    completedAt
`;

export const ORDER_ITEM_FIELDS = `
    id,
    orderChildId,
    itemNumber,
    quantity,
    quantityFilled,
    quantityRefunded,
    itemTax,
    itemTotal,
    itemRegular,
    itemSaved,
    refundTax,
    refundTotal,
    wooOrderItemId,
    stagedAt,
    product {
      childId
        groupId
        itemNumber
        size {
            sizeName
        },
        color {
            name
        },
        image,
        parent {
          groupId
            title,
            images
        },
      }
      productMeta {
        productsMaster
      }
`;

const ORDER_SHIPPING_ITEMS = `
      id,
      shippingName,
      shippingTax,
      shippingTotal,
      shippingActualCost
`;

export const GET_DELIVERY_ORDERS_LIST = gql`
  query OrderChildren($search: String, $orderChildStatus: String, $storeNumbers: [Int]) {
    orderChildren(
      fulfillmentMethod: "Delivery"
      search: $search
      orderChildStatus: $orderChildStatus
      storeNumbers: $storeNumbers
    ) {
      id
      orderNumber
      orderChildStatus
      order {
        id
        customerFirstName
        customerLastName
        shippingMethods {
          ${ORDER_SHIPPING_ITEMS}
        }
      }
    }
  }
`;

const FULL_ORDER_FIELDS = `
    ${ORDER_FIELDS}
    shippingMethods {
      ${ORDER_SHIPPING_ITEMS}
    }
    children {
      id
      orderNumber
      orderChildStatus
      fulfillmentMethod
      storeNumber
      orderItems {
        ${ORDER_ITEM_FIELDS}
      }
    }
`;

export const UPDATE_ORDER = gql`
  mutation Order($order: OrderInput) {
    updateOrder(order: $order) {
      ${FULL_ORDER_FIELDS}
    }
  }
`;

export const GET_DELIVERY_ORDER = gql`
query OrderChild($id: Int!) {
  orderChild(id: $id) {
    ${ORDER_CHILD_FIELDS}
    order {
      ${ORDER_FIELDS}
      shippingMethods {
        ${ORDER_SHIPPING_ITEMS}
      }
    }
    orderItems {
      ${ORDER_ITEM_FIELDS}
    }
    deliveries {
        ${ORDER_DELIVERY_FIELDS}
    }
  }
}
`;

export const UPDATE_DELIVERY_ORDER = gql`
  mutation OrderChild($orderChild: OrderChildInput) {
    updateOrderChild(orderChild: $orderChild) {
      ${ORDER_CHILD_FIELDS}
      order {
        ${ORDER_FIELDS}
        shippingMethods {
          ${ORDER_SHIPPING_ITEMS}
        }
      }
      orderItems {
        ${ORDER_ITEM_FIELDS}
      }
      deliveries {
          ${ORDER_DELIVERY_FIELDS}
      }
    }
  }
`;

export const GET_DELIVERIES = gql`
  query OrderDeliveries($startDate: Int, $endDate: Int, $storeNumber: Int) {
    orderDeliveries(startDate: $startDate, endDate: $endDate, storeNumber: $storeNumber) {
      ${ORDER_DELIVERY_FIELDS}
      orderChild {
        id
        orderNumber
        order {
          id
          customerFirstName
          customerLastName
        }
      }
    }
  }
`;

export interface IGetOrdersList {
  orders: Order[];
}

export interface IGetOrder {
  order: Order;
}
export interface IGetExportOrders {
  getExportOrders: ExportOrder[];
}

export interface IGetUnassociatedPackages {
  getUnassociatedPackages: PaginatedPackage;
}

export interface IGetUnassociatedRefunds {
  getUnassociatedRefunds: PaginatedRefund;
}

// DEPRECATED DELETE THESE LATER
export interface OrderChildQuery {
  orderChild: OrderChild;
}

export interface OrderChildrenQuery {
  orderChildren: OrderChild[];
}

export interface UpdateOrderChildQuery {
  updateOrderChild: OrderChild;
}

export interface OrderDeliveriesQuery {
  orderDeliveries: OrderDelivery[];
}

export interface OrdersQuery {
  orders: Order[];
}

export interface UpdateOrderQuery {
  updateOrder: Order;
}
