import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { queryReducer } from './store/reducers/tracker-link.reducer';
import { TrackerLinkService } from './tracker-link.service';

@NgModule({
  imports: [StoreModule.forFeature('queries', queryReducer)],
  providers: [TrackerLinkService],
})
export class TrackLinkModule {}
