export const fields = [
  'prop65_inhalent_message',
  'prop65_safe_harbor_message',
  'prop65_chemicals_specified_message',
];

export const fieldsMap = new Map<string, string>([
  [fields[0], 'inhalent'],
  [fields[1], 'safe_harbor'],
  [fields[2], 'chemicals_specified'],
]);
