import gql from 'graphql-tag';
import { Fulfillment, FulfillmentMetrics } from 'wilco-lib-models';

export const GET_PICKERS = gql`
  query pickers($storeNumber: Int!) {
    pickers(storeNumber: $storeNumber)
  }
`;

export const GET_AUDITORS = gql`
  query GetAuditors($storeNumber: Int!) {
    getAuditors(storeNumber: $storeNumber)
  }
`;

export const GET_FULFILLMENTS = gql`
  query fulfillments($params: FulfillmentParamsInput!) {
    fulfillments(params: $params) {
      id
      status
      picker
      processingAt
      updatedAt
      _packageId
      reservedAt
      notifiedAt
      package {
        id
        packageNumber
        processingAt
        status
        fulfillmentMethod
        orderItems {
          id
          quantityFilled
          quantity
          quantityRefunded
        }
        order {
          id
          status
        }
      }
    }
  }
`;

export const RESERVE_FULFILLMENTS = gql`
  mutation ReserveFulfillments($store: Int, $picker: String!) {
    reserveFulfillments(store: $store, picker: $picker) {
      id
      status
      picker
      processingAt
      _packageId
      reservedAt
      package {
        id
        packageNumber
        processingAt
        status
        orderItems {
          id
          quantity
          quantityFilled
          quantityRefunded
        }
        order {
          id
          status
        }
      }
    }
  }
`;

export const GET_FULFILLMENT_METRICS = gql`
  query GetFulfillmentMetrics($period: String) {
    getFulfillmentMetrics(period: $period) {
      bestPicker
      fastestResponse
    }
  }
`;

export const UPDATE_FULFILLMENTS = gql`
  mutation UpdateFulfillments($fulfillments: [FulfillmentInput!]!) {
    updateFulfillments(fulfillments: $fulfillments)
  }
`;

export interface IGetPickers {
  pickers: string[];
}
export interface IGetAuditors {
  getAuditors: string[];
}

export interface IReserveFulfillments {
  reserveFulfillments: Fulfillment[];
}
export interface IGetFulfillments {
  fulfillments: Fulfillment[];
}
export interface IGetFulfillmentMetrics {
  getFulfillmentMetrics: FulfillmentMetrics;
}
