import { ProductChild } from './product-child.model';
import { ProductMeta } from './product-meta.model';

export interface OrderItem {
  id: number;
  orderChildId: number;
  itemNumber: string;
  quantity: number;
  quantityFilled: number;
  quantityRefunded: number;
  itemTax: number;
  itemTotal: number;
  itemRegular: number;
  itemSaved: number;
  refundTax: number;
  refundTotal: number;
  wooOrderItemId: number;
  stagedAt: string;
  product?: ProductChild;
  productMeta?: ProductMeta;
}
