import gql from 'graphql-tag';
import { ProductColor } from '../models/product-color.model';

export const GET_PRODUCT_COLORS = gql`
  {
    productColors {
      id
      name
    }
  }
`;

export const UPDATE_PRODUCT_COLOR = gql`
  mutation UpdateProductColor($productColor: ProductColorInput) {
    updateProductColor(productColor: $productColor) {
      id
      name
    }
  }
`;

export interface ProductColorsQuery {
  productColors: ProductColor[];
}
