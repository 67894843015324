import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterState } from '../router-store.module';

export const ROUTER_FEATURE_KEY = 'router';

export const routerFeatureState =
  createFeatureSelector<RouterReducerState<RouterState>>(ROUTER_FEATURE_KEY);

export const routerState = createSelector(
  routerFeatureState,
  (featureState) => featureState.state
);

export const getRouterParams = createSelector(
  routerState,
  (state) => state.params
);

export const getRouterQueryParams = createSelector(
  routerState,
  (state) => state.queryParams
);

export const getRouterEntity = createSelector(
  routerState,
  (state) => state.entity
);

export const getRouterURL = createSelector(routerState, (state) => state.url);

export function getRouterParam(paramName: string) {
  return createSelector(getRouterParams, (params) => params[paramName]);
}
export function getRouterQueryParam(paramName: string) {
  return createSelector(
    getRouterQueryParams,
    (queryParams) => queryParams[paramName]
  );
}
