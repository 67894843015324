import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { Apollo } from 'apollo-angular';
import { TrucksQuery, GET_TRUCKS } from '../queries/truck.graphql';

@Injectable({
  providedIn: 'root',
})
export class TruckService {
  constructor(private logger: LoggerService, private apollo: Apollo) {}

  getTrucks(variables: { search?: string; storeNumber?: number } = {}) {
    return this.apollo
      .query<TrucksQuery>({
        query: GET_TRUCKS,
        variables,
        fetchPolicy: 'network-only',
      })
      .toPromise()
      .then(result => result.data.trucks);
  }
}
