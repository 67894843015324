import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth';
import { LoggerService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private logger: LoggerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.state$.pipe(
      map(state => {
        if (!state.isAuthenticated()) {
          return false;
        }
        if (!next.data.entity) {
          this.logger.debug(
            'PermissionGuard',
            'Permission Guard was called on a route with no provided permission to check',
            next
          );

          this.router.navigateByUrl('/dashboard');
          return false;
        }

        const permission = next.data?.permission
          ? next.data.permission
          : 'read';

        if (!state.hasPermission(`${next.data.entity}:${permission}`)) {
          this.router.navigateByUrl('/dashboard');
          return false;
        }

        return true;
      })
    );
  }
}
