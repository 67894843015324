import gql from 'graphql-tag';
import { Customer, Profile } from 'wilco-lib-models';

const CUSTOMER_FIELDS = `
id
name
address1
address2
city
zip
phoneNumber
categoryPlan
taxable
dateAccountOpened
lastPurchaseDate
lastActivityDate
militaryDiscountTill
seniorDiscountTill
`;

const PROFILE_FIELDS = `
profiles {
  id
  externalSource
  name
  email
  dateLinked
  email
  dateCreated
  billing
  shipping
  customerNumber
}
`;

export const GET_CUSTOMER = gql`
query Customer($id: String!){
    customer(id: $id) {
        ${CUSTOMER_FIELDS}
        ${PROFILE_FIELDS}
    }
}
`;

export const GET_CUSTOMERS_LITE = gql`
  query Customers($payload: JSON!) {
    customers(payload: $payload) {
      id
      name
      categoryPlan
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query Customers($payload: JSON!) {
    customers(payload: $payload) {
      id
      name
      categoryPlan
      ${PROFILE_FIELDS}
    }
  }
`;

export const GET_PROFILES = gql`
  query Profiles($payload: JSON!) {
    profiles(payload: $payload) {
      id
      externalSource
      email
      billing
      customerNumber
    }
  }
`;

export const GET_PROFILE = gql`
  query Profile($id: ID!) {
    profile(id: $id) {
      id
      externalSource
      email
      dateCreated
      billing
      shipping
      customerNumber
      categoryPlan
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($customer: CustomerInput!) {
    updateCustomer(customer: $customer)
  }
`;

export const LINK_PROFILE = gql`
  mutation LinkProfile(
    $externalId: String!
    $externalSource: String!
    $customerNumber: String!
  ) {
    linkProfile(
      externalId: $externalId
      externalSource: $externalSource
      customerNumber: $customerNumber
    )
  }
`;

export const UNLINK_PROFILE = gql`
  mutation UnlinkProfile($externalId: String!, $externalSource: String!) {
    unlinkProfile(externalId: $externalId, externalSource: $externalSource)
  }
`;

export interface CustomerQuery {
  customer: Customer;
}

export interface CustomersQuery {
  customers: Customer[];
}

export interface IGetProfiles {
  profiles: Profile[];
}

export interface IGetProfile {
  profile: Profile;
}

export interface UpdateCustomerMutation {
  updateCustomer: string;
}

export interface LinkProfileMutation {
  linkProfile: string;
}

export interface UnlinkProfileMutation {
  unlinkProfile: string;
}
