import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  private checkUserIsAuthenticated = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> => {
    return this.authService.state$.pipe(
      map(state => state.isAuthenticated()),
      tap(isAuthenticated =>
        !isAuthenticated
          ? this.router.navigate(['/auth/login'], {
              queryParams: {
                redirectUrl: state.url ? state.url.split('?')[0] : null,
                ...route.queryParams,
              },
              queryParamsHandling: 'merge',
            })
          : null
      )
    );
  };

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkUserIsAuthenticated(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkUserIsAuthenticated(route, state);
  }
}
