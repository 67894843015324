import gql from 'graphql-tag';
import { ProductSize } from '../models/product-size.model';

export const GET_PRODUCT_SIZES = gql`
  {
    productSizes {
      id
      sizeName
    }
  }
`;

export const UPDATE_PRODUCT_SIZE = gql`
  mutation UpdateProductSize($productSize: ProductSizeInput) {
    updateProductSize(productSize: $productSize) {
      id
      sizeName
    }
  }
`;

export interface ProductSizesQuery {
  productSizes: ProductSize[];
}
