import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GetObjectCommand,
  ListObjectsCommand,
  PutObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { environment } from '@env/environment';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap, retryWhen } from 'rxjs/operators';
import { Image } from 'wilco-lib-models';
import { WooApiService } from './woo-api.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  copiedImage: Image;
  bucket = new S3Client({
    region: 'us-west-2',
    credentials: {
      accessKeyId: environment.awsAccessKey,
      secretAccessKey: environment.awsSecretKey,
    },
  });

  constructor(private wooApiService: WooApiService) {}

  async uploadS3Image(key: string, image: Blob, imageType = 'image/jpeg') {
    const params = {
      Bucket: 'back40-media',
      Key: key,
      Body: image,
      ContentType: imageType,
    };
    return await this.bucket.send(new PutObjectCommand(params)).then(
      r => key,
      err => console.log(err)
    );
  }

  async getImageFromS3(key: string) {
    return getSignedUrl(
      this.bucket,
      new GetObjectCommand({
        Key: key,
        Bucket: 'back40-media',
      }),
      { expiresIn: 600 } // Expires in an hour
    );
  }

  async getS3Bucket(key: string) {
    return await this.bucket.send(
      new ListObjectsCommand({
        Bucket: 'back40-media',
        Delimiter: '/',
        Prefix: key,
      })
    );
  }
  uploadImage(file: File, image: NgxFileDropEntry, alt: string) {
    const formData = new FormData();
    formData.append('image', file, image.relativePath);
    formData.append('alt', alt);
    formData.append('action', 'upload');

    return this.wooApiService.makeRequest('image', formData).pipe(
      map(event => this.getEventMessage(event)),
      retryWhen(
        this.retryStrategy({
          maxRetryAttempts: 2,
          includedStatusCodes: [1],
        })
      ),
      catchError(this.handleError)
    );
  }

  uploadImageByUrl(url: string, alt: string) {
    const formData = new FormData();
    formData.append('url', url);
    formData.append('alt', alt);
    formData.append('action', 'upload-url');

    return this.wooApiService.makeRequest('image', formData).pipe(
      map(event => this.getEventMessage(event)),
      retryWhen(
        this.retryStrategy({
          maxRetryAttempts: 2,
          includedStatusCodes: [1],
        })
      ),
      catchError(this.handleError)
    );
  }

  updateImageAlt(wooId: number, alt: string) {
    return new Promise((resolve, reject) => {
      if (!wooId) {
        reject('Missing ID');
      }

      const formData = new FormData();
      formData.append('wooId', wooId.toString());
      formData.append('alt', alt);
      formData.append('action', 'update');

      this.wooApiService
        .makeRequest('image', formData)
        .toPromise()
        .then((res: any) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        });
    });
  }

  deleteImage(wooId: number) {
    return new Promise((resolve, reject) => {
      if (!wooId) {
        reject('Missing ID');
      }

      const formData = new FormData();
      formData.append('wooId', wooId.toString());
      formData.append('action', 'remove');

      this.wooApiService
        .makeRequest('image', formData)
        .toPromise()
        .then((res: any) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        });
    });
  }

  getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          type: 'progress',
          total: 0,
          message: `Image upload has started`,
        };

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        const relativePercentDone = (percentDone / 100) * 80;
        if (percentDone < 100) {
          return {
            type: 'progress',
            total: relativePercentDone,
            message: `Image is ${percentDone}% uploaded.`,
          };
        }
        return {
          type: 'progress',
          total: 80,
          message: `Image is now being processed.`,
        };

      case HttpEventType.Response:
        // Sometimes Offload Media plugin fails to upload to S3 causing the image to just disappear into the nether
        // We catch that here and "fail" the upload so the user knows to re-upload
        if (event.body.data && !event.body.data.image)
          throw {
            status: 1,
            error: 'Image upload failed to offload. You can retry the upload.',
          };

        return { type: 'response', message: event.body };

      default:
        return;
    }
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      return throwError(`An error occured: ${error.error.message}`);
    } else {
      error =
        error.error && error.error.error ? error.error.error : error.error; /// woooooooaaaaaahhhhhhh
      return throwError(error);
    }
  }

  copyImage(image: any) {
    this.copiedImage = image;
  }

  getCopiedImage() {
    if (!this.copiedImage) {
      return false;
    }

    return this.copiedImage;
  }

  retryStrategy = ({
    maxRetryAttempts = 2,
    includedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    includedStatusCodes?: number[];
  } = {}) => (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;

        if (
          retryAttempt > maxRetryAttempts ||
          !includedStatusCodes.find(e => e === error.status)
        ) {
          return throwError(error);
        }

        return timer(1);
      })
    );
  };
}
