import gql from 'graphql-tag';
import { WoosyncJob } from '../models/woosync.model';

export const GET_QUEUE_COUNTS = gql`
  query GetQueueCounts {
    getQueueCounts {
      worker
      processor
    }
  }
`;

export const PURGE_QUEUES = gql`
  mutation PurgeQueues($payload: PurgeQueueParams!) {
    purgeQueues(payload: $payload)
  }
`;

export const SEND_TO_QUEUE = gql`
  mutation SendToQueue($payload: SendToQueueParams!) {
    sendToQueue(payload: $payload)
  }
`;

export const GET_WOOSYNC_JOB = gql`
  query WoosyncJob($groupId: Int!) {
    woosync(groupId: $groupId) {
      groupId
      status
      wooId
      wooUrl
      children
      syncData
      datePublished
      lastRun
    }
  }
`;

export const RESET_WOOSYNC_JOBS = gql`
  mutation ResetWoosyncJob {
    resetWoosync
  }
`;

export const UPDATE_VISIBLE = gql`
  mutation UpdateVisible($itemNumber: String!, $visible: String!) {
    updateVisible(itemNumber: $itemNumber, visible: $visible)
  }
`;

export interface WoosyncJobQuery {
  woosync: WoosyncJob;
}

export interface GetQueueCounts {
  getQueueCounts: { worker: number; processor: number };
}

export interface PurgeQueues {
  purgeQueues: string;
}
