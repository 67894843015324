import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs/operators';
import { ErrorLog } from '../models';
import { ADD_ACTIVITY_LOG } from '../queries/activity-log.graphql';
import { UPDATE_ERROR_LOG } from '../queries/error-log.graphql';

enum LogLevel {
  Off = 0,
  Debug,
  Info,
  Warning,
  Error,
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private level = LogLevel.Debug;

  private colors = {
    [LogLevel.Error]: '#dc3545',
    [LogLevel.Warning]: '#ffc107',
    [LogLevel.Info]: '#17a2b8',
    [LogLevel.Debug]: '#6c757d',
  };

  constructor(private apollo: Apollo) {}

  private log(
    func: () => void,
    level: LogLevel,
    source: string,
    objects: any[]
  ) {
    if (level >= this.level) {
      func.apply(console, [
        '%c' + source,
        'font-weight: bold; color: ' + this.colors[level],
        objects,
      ]);

      if (level > LogLevel.Info) {
        const errorLog: ErrorLog = {
          level: LogLevel[level],
          source,
          data: objects,
        };

        this.apollo
          .mutate({
            mutation: UPDATE_ERROR_LOG,
            variables: {
              errorLog,
            },
            errorPolicy: 'all',
          })
          .pipe(take(1))
          .subscribe();
      }
    }
  }

  enableProductionMode() {
    this.level = LogLevel.Warning;
  }

  debug(source: string, ...objects: any[]) {
    this.log(console.log, LogLevel.Debug, source, objects);
  }

  info(source: string, ...objects: any[]) {
    // eslint-disable-next-line no-console
    this.log(console.info, LogLevel.Info, source, objects);
  }

  warn(source: string, ...objects: any[]) {
    this.log(console.warn, LogLevel.Warning, source, objects);
  }

  error(source: string, ...objects: any[]) {
    this.log(console.error, LogLevel.Error, source, objects);
  }

  gqlError(...objects: any[]) {
    // todo: handle gql errors here
  }

  action(entity: string, id: string | number, action: string, message: string) {
    let user = localStorage.getItem('userName') ?? 'Unknown';

    this.debug('Action Logger', entity, id, action, message);

    this.apollo
      .mutate({
        mutation: ADD_ACTIVITY_LOG,
        variables: {
          activityLog: {
            user,
            entityType: entity,
            entityId: id.toString(),
            action,
            message,
          },
        },
        errorPolicy: 'all',
      })
      .pipe(take(1))
      .subscribe();
  }
}
