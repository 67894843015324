import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { takeUntil, map } from 'rxjs/operators';

import { ProductColor } from '../models/product-color.model';
import { ProductColorsQuery, GET_PRODUCT_COLORS, UPDATE_PRODUCT_COLOR } from '../queries/product-color.graphql';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class ProductColorService implements OnDestroy {
  private productColors: ProductColor[] = [];
  private productColors$: Subscription;

  productColorsChanged$ = new BehaviorSubject<ProductColor[]>([]);

  protected onDestroy = new Subject<void>();

  constructor(private apollo: Apollo, private logger: LoggerService) {}

  initColors() {
    this.productColors$ = this.apollo
      .query<ProductColorsQuery>({
        query: GET_PRODUCT_COLORS,
      })
      .pipe(takeUntil(this.onDestroy))
      .subscribe(result => {
        this.productColors = result.data.productColors;
        this.productColorsChanged$.next(this.productColors.slice());

        this.logger.debug('ProductColorService', 'productColors set to', this.productColors);
      });
  }

  getColors() {
    return this.productColors.slice();
  }

  getColorByName(name: string) {
    if (!name) {
      return null;
    }

    const color = this.productColors.find(c => c.name.toLowerCase() === name.toLowerCase());
    return color ? color : null;
  }

  updateProductColor(productColor: ProductColor) {
    return this.apollo
      .mutate({
        mutation: UPDATE_PRODUCT_COLOR,
        variables: {
          productColor,
        },
        errorPolicy: 'all',
      })
      .pipe(
        map((r: any) => {
          if (r.errors && r.errors.length > 0) {
            return r;
          }
          return r.data.updateProductColor;
        })
      );
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
