import gql from 'graphql-tag';
import { Product, ProductWarning } from 'wilco-lib-models';

const PRODUCT_GROUP_FIELDS_SEARCH = `
    id,
    title
`;
const PRODUCT_GROUP_FIELDS_LITE = `
    id,
    title,
    images,
    featured,
    featuredStartDate,
    featuredEndDate,
    featuredPosition,
    items {
      id,
      image
    }
`;

const PRODUCT_GROUP_FIELDS = `
  id,
  title,
  description,
  _brandId,
  brand {
    id,
    name
  },
  gender,
  categories {
    id,
    name
  },
  images,
  imageDefaults,
  highlights,
  species {
    id,
    name
  },
  hidePricingOnline,
  notSoldOnline,
  pickupOnly,
  limitedStockQuantity,
  deliveryAvailable,
  deliveryExtraHandling,
  globalPromotion,
  keywords,
  featured,
  featuredStartDate,
  featuredEndDate,
  featured,
  customTab,
  publish,
  publishSettings,
  purchaseLimit
  excludedStates,
  requiresFraudCheck,
  taxCode,
  chemicals {
    id,
    name,
    typeOfToxicity
  }
  prop65WarningType,
  prop65Warning {
    id,
    title,
    message,
    args,
    type,
    warningArgs
  }
  productWarnings {
    id,
    title,
    message,
    args,
    type
  },
  createdAt,
  updatedAt,
  items {
    id,
    productId,
    _sizeId
    _colorId,
    size {
      id,
      name
    },
    color {
      id,
      name
    },
    image,
    publish,
    masterData,
    locationData,
    locationSecondaryData

  }
  relatedProducts {
    id
    title
  }
  woosync {
    id
    ecomId
    ecomUrl
    status
    lastRun
    datePublished
    children
    syncData
  }
`;

export const GET_PRODUCTS_SEARCH = gql`
  query GetProducts($config: IGetProductsConfig!) {
    getProducts(config: $config) {
      ${PRODUCT_GROUP_FIELDS_SEARCH}
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($config: IGetProductsConfig!) {
    getProducts(config: $config) {
      ${PRODUCT_GROUP_FIELDS_LITE}
    }
  }
`;

export const GET_PRODUCT = gql`
  query GetProduct($id: Int!) {
    getProduct(id: $id) {
      ${PRODUCT_GROUP_FIELDS}
    }
  }
`;

export const GET_PRODUCT_WARNINGS = gql`
  query GetProductWarnings {
    getProductWarnings {
      id
      title
      type
      args
      message
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($product: ProductInput!, $user: String!) {
    createProduct(product: $product, user: $user)
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($product: ProductInput!) {
    updateProduct(product: $product)
  }
`;

export const UPDATE_PRODUCTS = gql`
  mutation UpdateProducts($products: [ProductInput!]!) {
    updateProducts(products: $products)
  }
`;

export const MERGE_PRODUCTS = gql`
  mutation MergeProducts($destination: Int!, $sources: [Int!]!) {
    mergeProducts(destination: $destination, sources: $sources)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: Int!) {
    deleteProduct(id: $id)
  }
`;

export const SYNC_PRODUCT_TO_ECOM = gql`
  mutation syncProductToEcom($id: Int!) {
    syncProductToEcom(id: $id) {
      status
      message
    }
  }
`;

export const SYNC_PRODUCTS_TO_ECOM = gql`
  mutation syncProductsToEcom {
    syncProductsToEcom {
      status
      message
    }
  }
`;

export interface IGetProduct {
  getProduct: Product;
}

export interface IGetProducts {
  getProducts: Product[];
}
export interface IGetProductWarnings {
  getProductWarnings: ProductWarning[];
}
export interface ICreateProduct {
  createProduct: number;
}
export interface IMergeProducts {
  mergeProducts: string;
}
export interface IDeleteProduct {
  deleteProduct: string;
}
