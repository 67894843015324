import { Injectable } from '@angular/core';
import { selectProductId } from '@app/modules/products/store/actions/product.actions';
import { Store } from '@ngrx/store';
import { shareReplay } from 'rxjs/operators';
import { AppState } from '../app-store.module';

@Injectable()
export class RouterService {
  readonly productId$ = this.store.select(selectProductId).pipe(shareReplay(1));

  constructor(private store: Store<AppState>) {}
}
