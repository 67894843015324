import gql from 'graphql-tag';
import { QuantityBreakCode } from '../models';
import { QuantityBreak, QuantityBreakInput } from 'wilco-lib-models';

const QUANTITY_BREAK_FIELDS = `
id
type
note
discounts
`;

export const GET_QUANTITY_BREAK_CODE = gql`
  query QuantityBreakCode($code: Int!) {
    quantityBreakCode(code: $code) {
      code
      type
      note
      qty1
      break1
      qty2
      break2
      qty3
      break3
      qty4
      break4
      qty5
      break5
    }
  }
`;

export const GET_QUANTITY_BREAKS = gql`
  query QuantityBreakCodes {
    getQuantityBreaks {
      ${QUANTITY_BREAK_FIELDS}
    }
  }
`;

export const UPDATE_QUANTITY_BREAKS = gql`
  mutation UpdateQuantityBreaks($quantityBreaks: [QuantityBreakInput!]!) {
    updateQuantityBreaks(quantityBreaks: $quantityBreaks)
  }
`;

export interface QuantityBreakCodeQuery {
  quantityBreakCode: QuantityBreakCode;
}

export interface QuantityBreakResultsQuery {
  getQuantityBreaks: QuantityBreak[];
}

export interface UpdateQuantityBreaksQuery {
  updateQuantityBreaks: QuantityBreak[];
}
