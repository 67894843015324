import gql from 'graphql-tag';
import { Truck } from '../models';

export const GET_TRUCKS = gql`
  query Trucks($search: String, $storeNumber: Int) {
    trucks(search: $search, storeNumber: $storeNumber) {
      id
      truckName
      storeNumber
    }
  }
`;

export interface TrucksQuery {
  trucks: Truck[];
}
