import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CanActivatePageGuard  {
  constructor() {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const pageId = parseInt(route.queryParams.pageId, 10);
    return of(true);
    // return this.adMediaService.media$.pipe(
    //   map(media => {
    //     if (!media) return true;
    //     const isValid = media.pages.reduce(
    //       (acc, curr) => acc || curr.id === pageId,
    //       false
    //     );

    //     if (isValid) return true;

    //     this.router.navigateByUrl('/promotions/planner');
    //     return false;
    //   }),
    //   catchError(() => {
    //     this.router.navigateByUrl('/promotions/planner');
    //     return of(false);
    //   })
    // );
  }
}
