import gql from 'graphql-tag';
import { Refund } from 'wilco-lib-models';

export const UPDATE_REFUND = gql`
  mutation UpdateRefund($refund: RefundInput!) {
    updateRefund(refund: $refund)
  }
`;

export const DELETE_REFUND = gql`
  mutation DeleteRefund($refund: RefundInput!) {
    deleteRefund(refund: $refund)
  }
`;

export const CREATE_ORDER_REFUND = gql`
  mutation CreateOrderRefund($order: OrderInput!) {
    createOrderRefund(order: $order)
  }
`;

export const GET_REFUNDS = gql`
  query GetRefunds($params: JSON!) {
    getRefunds(params: $params) {
      id
      integratedAt
      package {
        packageNumber
        order {
          id
          orderNumber
        }
      }
    }
  }
`;

export const INTEGRATE_REFUND = gql`
  mutation IntegrateRefund($id: Int!) {
    integrateRefund(id: $id)
  }
`;

export const INTEGRATE_FAILED_REFUNDS = gql`
  mutation IntegrateFailedRefunds {
    integrateFailedRefunds
  }
`;

export interface IUpdateRefund {
  updateRefund: string;
}

export interface IDeleteRefund {
  deleteRefund: string;
}

export interface IGetRefunds {
  getRefunds: Refund[];
}
