import { AppState } from '@app/shared/stores/app-store.module';
import { getRouterParam } from '@app/shared/stores/router/actions/router.action';
import { createAction, createSelector } from '@ngrx/store';
import {
  IGetProductsConfig,
  Notification,
  Product,
  ProductWarning,
} from 'wilco-lib-models';
import { createCategorySuccess } from './category.actions';
import {
  createItemSuccess,
  deleteItemSuccess,
  updateItemSuccess,
} from './item.actions';

// Selectors
export const selectProductStore = createSelector(
  (state: AppState) => state.productStore,
  (productStore: AppState['productStore']) => productStore
);

export const selectProductList = createSelector(
  (state: AppState) => state.productStore,
  (productStore: AppState['productStore']) => productStore.productList
);

export const selectProduct = createSelector(
  (state: AppState) => state.productStore,
  (productStore: AppState['productStore']) => productStore.product
);

export const selectProductWarnings = createSelector(
  (state: AppState) => state.productStore,
  (productStore: AppState['productStore']) => productStore.productWarnings
);

export const selectProductConfig = createSelector(
  (state: AppState) => state.productStore,
  (productStore: AppState['productStore']) => productStore.config
);

export const selectProductHasChanges = createSelector(
  (state: AppState) => state.productStore,
  (productStore: AppState['productStore']) => productStore.hasChanges
);

export const selectProductId = createSelector(
  getRouterParam('productId'),
  (id: string) => (id ? +id : null)
);

// Actions
export const fetchProductList = createAction(
  '[Product] Fetch Product List',
  (config: IGetProductsConfig) => ({ config })
);

export const fetchProductWarnings = createAction(
  '[Product] Fetch Product Warnings'
);

export const getProductList = createAction(
  '[Product] Get Product List',
  (productList: Product[]) => ({ productList })
);

export const getProduct = createAction(
  '[Product] Get Product',
  (product: Product) => ({ product })
);

export const getProductWarnings = createAction(
  '[Product] Get Product Warnings',
  (productWarnings: ProductWarning[]) => ({ productWarnings })
);

export const createProduct = createAction(
  '[Product] Product creating',
  (product: Product, user: string) => ({
    product,
    user,
  })
);

export const createProductSuccess = createAction(
  '[Product] Product created successfully'
);

export const updateProduct = createAction(
  '[Product] Product updating',
  (product: Product, notification: Notification) => ({
    product,
    notification,
  })
);

export const updateProducts = createAction(
  '[Product] Products updating',
  (products: Product[]) => ({
    products,
  })
);

export const updateProductSuccess = createAction(
  '[Product] Product updated successfully'
);

export const updateProductsSuccess = createAction(
  '[Product] Products updated successfully'
);

export const mergeProducts = createAction(
  '[Product] Merging Products',
  (destination: number, sources: number[]) => ({ destination, sources })
);

export const mergeProductsSuccess = createAction(
  '[Product] Products merged successfully'
);

export const deleteProduct = createAction(
  '[Product] Product deleting',
  (product: Product) => ({ product })
);

export const deleteProductSuccess = createAction(
  '[Product] Product deleted successfully'
);

export const hasChanges = createAction(
  '[Product] Product has changes',
  (hasChanges: boolean) => ({ hasChanges })
);

export const flagWoosync = createAction('[Product] Woosync Pending');

export const resetProductStore = createAction('[Product] Product store reset');
export const clearProduct = createAction('[Product] Product cleared');
export const syncProductToEcom = createAction(
  '[Product] Product sync to Ecom',
  (id: number) => ({ id })
);
export const syncProductsToEcom = createAction(
  '[Product] Products sent to sync to Ecom'
);
export const syncProductToEcomSuccess = createAction(
  '[Product] Product synced to Ecom successfully'
);
export const syncProductsToEcomSuccess = createAction(
  '[Product] Products sent to sync with Ecom'
);

export const productUpdated = [
  updateProductSuccess,
  createCategorySuccess,
  createItemSuccess,
  createProductSuccess,
  updateItemSuccess,
  mergeProductsSuccess,
  deleteItemSuccess,
  resetProductStore,
];
