import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  constructor(
    private router: Router,
    private _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService
  ) {}

  async init() {
    await this._NgxZendeskWebwidgetService.initZendesk();

    /**
     * Little hacky but essentially if we are using an old ng1 route, keep ZD FAB visible
     * Otherwise, hide it entirely until we want it.
     */
    if (
      this.router.url.includes('app') ||
      this.router.url.includes('dashboard')
    ) {
      await this.show();
    } else {
      await this.close();

      this._zendesk('webWidget:on', 'close', () => {
        this._zendesk('webWidget', 'hide');
      });
    }
  }

  private get _zendesk() {
    return this._NgxZendeskWebwidgetService.zE;
  }

  async show() {
    if (!this._zendesk) await this.init();

    this._zendesk('webWidget', 'show');
  }

  async open() {
    if (!this._zendesk) await this.init();

    this._zendesk('webWidget', 'show');
    this._zendesk('webWidget', 'open');
  }

  async close() {
    if (!this._zendesk) await this.init();

    this._zendesk('webWidget', 'close');
    this._zendesk('webWidget', 'hide');
  }
}
