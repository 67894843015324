import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from '../app-store.module';
import {
  removeQuery,
  selectTrackedQueries,
} from './store/actions/tracker-link.actions';

@Injectable({ providedIn: 'root' })
export class TrackerLinkService {
  constructor(private store: Store<AppState>) {}

  get trackedQueries() {
    return lastValueFrom(this.store.select(selectTrackedQueries).pipe(take(1)));
  }

  removeTrackedQuery(id: string) {
    this.store.dispatch(removeQuery(id));
  }
}
