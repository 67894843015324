import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth';
import { Apollo } from 'apollo-angular';
import { Subject, lastValueFrom } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { ZendeskService } from './core/services/zendesk.service';
import { TrackerLinkService } from './shared/stores/tracker-link/tracker-link.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'back40';
  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private httpClient: HttpClient,
    private trackerLinkService: TrackerLinkService,
    private router: Router,
    private zendesk: ZendeskService,
    private apollo: Apollo
  ) {
    /**
     * Kick off our Auth workflow
     */
    this.authService.state$.pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  async ngOnInit() {
    await this.initMutations();
    // bootstrap our old AngularJS code with ngUpgrade
    // this.upgrade.bootstrap(document.body, ['back40App']);

    // add our environment to the body class
    this.renderer.addClass(document.body, environment.name);

    // add lucky orange if Production
    if (environment.name === 'production') {
      const s = this.renderer.createElement('script');
      s.type = 'text/javascript';
      s.text = `
          window.__lo_site_id = 143729;
          (function() {
              var wa = document.createElement('script'); wa.type = 'text/javascript'; wa.async = true;
              wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
              var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(wa, s);
              })();`;
      this.renderer.appendChild(document.head, s);
    }

    // One day Michael complained that Back40 was too boring, so we're making it entertaining for him
    this.tellJoke();

    // Init foundation
    // $(document).foundation();
    // await this.trackerLinkService.initMutations();
    await this.zendesk.init();
  }

  tellJoke() {
    setTimeout(async () => {
      const { isMichael, isSalem } = await this.authService.state$
        .pipe(take(1))
        .toPromise();

      if (isMichael || (isSalem && this.router.url === '/mission-control')) {
        this.httpClient
          .get('https://icanhazdadjoke.com/', {
            headers: new HttpHeaders({
              Accept: 'application/json',
            }),
          })
          .pipe(take(1))
          .subscribe((data: any) => {
            this.snackbar.open(data.joke, 'Haha', { duration: 30000 });
            this.tellJoke();
          });
      }
    }, 300000);
  }

  /**
   * When the back40 is reloaded we get all of the trackedQueries that are saved in
   * localStorage (if any) and we send those request to omniapi. They will resolve
   * normally and be removed from the trackedQueries list when they are done.
   */
  async initMutations() {
    const trackedQueries = await this.trackerLinkService.trackedQueries;

    const promises = [];
    trackedQueries.forEach((trackedQuery) => {
      const context = JSON.parse(trackedQuery.contextJSON);
      const query = JSON.parse(trackedQuery.queryJSON);
      const variables = JSON.parse(trackedQuery.variablesJSON);
      promises.push(
        lastValueFrom(
          this.apollo
            .use('omniApi')
            .mutate({
              context,
              mutation: query,
              optimisticResponse: context.optimisticResponse,
              variables,
            })
            .pipe(take(1))
        )
      );
      // This needs to be here because on reload we don't have our context in the link anymore
      this.trackerLinkService.removeTrackedQuery(trackedQuery.id);
    });

    try {
      await Promise.all(promises);
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
