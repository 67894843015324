export interface WoosyncJob {
  groupId: number;
  status: string;
  wooId: number;
  wooUrl: string;
  children: any[];
  syncData: any[] | any;
  datePublished: string;
  lastRun: string;
}
