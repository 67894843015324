import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { EMPTY, Subject } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import {
  CreateActivityLogParams,
  GetActivityLogParams,
} from 'wilco-lib-models';
import { AuthService } from '../auth';
import {
  CREATE_ACTIVITY_LOG,
  GET_ACTIVITY_LOGS,
  ICreateActivityLog,
  IGetActivityLogs,
} from '../queries/activity-log.graphql';

@Injectable({ providedIn: 'root' })
export class ActivityLogService {
  private _updateLogs$ = new Subject<void>();

  constructor(private apollo: Apollo, private authService: AuthService) {}

  getActivityLog(payload: GetActivityLogParams) {
    return this._updateLogs$.asObservable().pipe(
      startWith(EMPTY),
      switchMap(() =>
        this.apollo
          .use('omniApi')
          .watchQuery<IGetActivityLogs>({
            query: GET_ACTIVITY_LOGS,
            variables: {
              payload,
            },
            fetchPolicy: 'network-only',
            pollInterval: 5000,
          })
          .valueChanges.pipe(map(result => result.data.getActivityLogs))
      )
    );
  }

  createLog(payload: CreateActivityLogParams) {
    return this.authService.state$.pipe(
      map(({ userName }) => userName),
      switchMap(userName =>
        this.apollo
          .use('omniApi')
          .mutate<ICreateActivityLog>({
            mutation: CREATE_ACTIVITY_LOG,
            variables: {
              payload: { ...payload, user: userName },
            },
          })
          .pipe(
            map(result => result.data.createActivityLog),
            tap(() => this._updateLogs$.next())
          )
      )
    );
  }
}
