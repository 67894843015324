import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaders: BehaviorSubject<Set<string>>[] = [];

  constructor() {}

  getLoader(loader: string) {
    if (!this.loaders[loader]) {
      return (this.loaders[loader] = new BehaviorSubject<Set<string>>(
        new Set()
      ));
    }

    return this.loaders[loader];
  }

  addToLoader(loader: string, message: string): BehaviorSubject<Set<string>> {
    if (!this.loaders[loader]) {
      this.loaders[loader] = new BehaviorSubject<Set<string>>(
        new Set([message])
      );
      return this.loaders[loader];
    }

    this.loaders[loader].next(this.loaders[loader].getValue().add(message));
    return this.loaders[loader];
  }

  removeFromLoader(loader: string, message: string) {
    if (!this.loaders[loader]) {
      return null;
    }

    const loaderMessages: Set<string> = this.loaders[loader].getValue();
    loaderMessages.delete(message);
    this.loaders[loader].next(loaderMessages);
  }
}
