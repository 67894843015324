import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ProductSize } from '../models/product-size.model';
import {
  GET_PRODUCT_SIZES,
  ProductSizesQuery,
  UPDATE_PRODUCT_SIZE,
} from '../queries/product-size.graphql';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class ProductSizeService implements OnDestroy {
  private productSizes: ProductSize[] = [];

  productSizesChanged$ = new BehaviorSubject<ProductSize[]>([]);

  protected onDestroy = new Subject<void>();

  constructor(private apollo: Apollo, private logger: LoggerService) {}

  initSizes() {
    this.apollo
      .query<ProductSizesQuery>({
        query: GET_PRODUCT_SIZES,
      })
      .pipe(takeUntil(this.onDestroy))
      .subscribe(result => {
        this.productSizes = result.data.productSizes;
        this.productSizesChanged$.next(this.productSizes.slice());

        this.logger.debug(
          'ProductSizeService',
          'productSizes set to',
          this.productSizes
        );
      });
  }

  getSizes() {
    return this.productSizes.slice();
  }

  getSizeByName(name: string) {
    if (!name) {
      return null;
    }

    const size = this.productSizes.find(
      s => s.sizeName.toLowerCase() === name.toLowerCase()
    );
    return size ? size : null;
  }

  updateProductSize(productSize: ProductSize) {
    return this.apollo
      .mutate({
        mutation: UPDATE_PRODUCT_SIZE,
        variables: {
          productSize,
        },
        errorPolicy: 'all',
      })
      .pipe(
        map((r: any) => {
          if (r.errors && r.errors.length > 0) {
            return r;
          }
          return r.data.updateProductSize;
        })
      );
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
