import gql from 'graphql-tag';
import { ActivityLog } from 'wilco-lib-models';

export const ADD_ACTIVITY_LOG = gql`
  mutation AddActivityLog($activityLog: ActivityLogInput) {
    addActivityLog(activityLog: $activityLog) {
      id
    }
  }
`;

export const GET_ACTIVITY_LOGS = gql`
  query GetActivityLogs($payload: GetActivityLogParams!) {
    getActivityLogs(payload: $payload) {
      id
      user
      action
      message
      createdAt
    }
  }
`;

export const CREATE_ACTIVITY_LOG = gql`
  mutation CreateActivityLog($payload: CreateActivityLogParams!) {
    createActivityLog(payload: $payload)
  }
`;

export interface ActivityLogQuery {
  activityLog: ActivityLog[];
}
export interface IGetActivityLogs {
  getActivityLogs: ActivityLog[];
}
export interface ICreateActivityLog {
  createActivityLog: string;
}
