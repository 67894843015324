import { Image } from './image.model';
import { ProductSize } from './product-size.model';
import { ProductColor } from './product-color.model';
import { ProductGroup } from './product-group.model';
import { ProductMeta } from './product-meta.model';

export class ProductChild {
  childId: number;
  groupId: number;
  itemNumber: string;
  image: Image;
  sizeId: number;
  colorId: number;
  meta: object;
  publish: boolean;
  size: ProductSize;
  color: ProductColor;
  parent: ProductGroup;
  productMeta: ProductMeta;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
