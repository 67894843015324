import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth';
import { LoggerService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class NgFallbackGuard  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private logger: LoggerService
  ) {}

  canActivate() {
    return this.authService.state$.pipe(
      map(state => {
        const authenticated = state.isAuthenticated();

        if (authenticated) {
          this.router.navigate([state.defaultRoute]);
        } else {
          this.logger.debug(
            'NgFallbackGuard',
            'Redirecting to the auth module.'
          );
          this.router.navigate(['/auth/login']);
        }

        return authenticated;
      })
    );
  }
}
