import { AppState } from '@app/shared/stores/app-store.module';
import { createAction, createSelector } from '@ngrx/store';

export interface TrackedQuery {
  contextJSON: string;
  id: string;
  name: string;
  queryJSON: string;
  variablesJSON: string;
}

export const selectTrackedQueries = createSelector(
  (state: AppState) => state.queries,
  (queries: TrackedQuery[]) => queries
);

export const getQueries = createAction(
  '[Tracker-Link] Get Stored Graphql Mutations',
  (queries: TrackedQuery[]) => ({ queries })
);

export const addQuery = createAction(
  '[Tracker-Link] Add Graphql Query',
  (query: TrackedQuery) => ({ query })
);

export const removeQuery = createAction(
  '[Tracker-Link] Remove Graphql Query',
  (id: string) => ({ id })
);
