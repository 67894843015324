import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  GET_PRODUCTS,
  IGetProducts,
} from '@app/modules/products/store/graphql/products.graphql';
import { Product } from 'wilco-lib-models';
import { ProductGroup } from '../models/product-group.model';
import {
  DELETE_PRODUCT_GROUP,
  GET_PRODUCT_GROUP,
  GET_PRODUCT_GROUPS,
  GET_PRODUCT_GROUPS_BY_IDS,
  GET_PRODUCT_GROUPS_FEATURED,
  GET_PRODUCT_GROUPS_FULL_BY_IDS,
  GET_PRODUCT_GROUPS_GLOBAL_PROMOTION,
  GET_PRODUCT_GROUPS_LITE,
  ProductGroupQuery,
  ProductGroupsQuery,
  SEARCH_PRODUCT_GROUPS,
  SEARCH_PRODUCT_GROUPS_FOR_RELATED,
  UPDATE_PRODUCT_GROUP,
} from '../queries/product-group.graphql';
import { LoggerService } from './logger.service';
import { ProductChildService } from './product-child.service';
import { WoosyncService } from './woosync.service';

@Injectable({
  providedIn: 'root',
})
export class ProductGroupService implements OnDestroy {
  private getProductGroups$: Subscription; // ref to our GQL query subscription

  private searchTerm = ''; // holds our current search term
  productGroupChanged$ = new Subject<ProductGroup>(); // subject to notify when productgroup changed
  productGroupsChanged$ = new Subject<ProductGroup[]>(); // subject to notify listeners to this service that the product groups changed

  productGroupChangedByImport$ = new Subject<ProductGroup>();
  productGroupChangedByDelete$ = new EventEmitter();

  constructor(
    private apollo: Apollo,
    private logger: LoggerService,
    private productChildService: ProductChildService,
    private woosyncService: WoosyncService
  ) {}

  isDifferent(subject, target) {
    subject = subject.map((v) => this.stripTypename(v));
    target = target.map((v) => this.stripTypename(v));
    return JSON.stringify(subject) !== JSON.stringify(target);
  }

  stripTypename(obj: any) {
    for (const property in obj) {
      if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        delete obj.property;
        const newData = this.stripTypename(obj[property]);
        obj[property] = newData;
      } else {
        if (property === '__typename') {
          delete obj[property];
        }
      }
    }
    return obj;
  }

  convertToModel(groups) {
    if (!groups) {
      return false;
    }

    return groups.map((group) => new ProductGroup(group));
  }

  // gets a set of Product Groups, with only a lite subset of fields
  // @param fullData - whether we get all fields or just a small subset
  getProductGroupsByIds(groupIds: number[], fullData: boolean = false) {
    return this.apollo
      .watchQuery<ProductGroupsQuery>({
        query: fullData
          ? GET_PRODUCT_GROUPS_FULL_BY_IDS
          : GET_PRODUCT_GROUPS_BY_IDS,
        variables: {
          groupIds,
        },
      })
      .valueChanges.pipe(map((result) => result.data.productGroups));
  }

  getFeaturedProductGroups() {
    return this.apollo
      .query<ProductGroupsQuery>({
        query: GET_PRODUCT_GROUPS_FEATURED,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result) => result.data.productGroups));
  }

  getGlobalPromotionProductGroups() {
    return this.apollo
      .query<ProductGroupsQuery>({
        query: GET_PRODUCT_GROUPS_GLOBAL_PROMOTION,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result) => result.data.productGroups));
  }

  // refetch our ProductGroups query with the provided search term
  searchProductGroups(search: string, filters: any = {}) {
    this.searchTerm = search;

    if (!search && !filters) {
      return EMPTY;
    }

    const mappedFilters: any = {};
    // eslint-disable-next-line guard-for-in
    for (const name in filters) {
      if (name === 'Brand') {
        mappedFilters.brandId = filters[name].value;
      } else if (name === 'Fineline') {
        mappedFilters.fineline = filters[name];
      } else if (name === 'Exclude') {
        mappedFilters.exclude = filters[name];
      } else if (name === 'Include') {
        mappedFilters.include = filters[name];
      } else if (name === 'Show Only') {
        mappedFilters.showOnly = filters[name];
      } else if (name === 'Sort By') {
        mappedFilters.sortBy = filters[name];
      }
    }

    return this.apollo
      .watchQuery<ProductGroupsQuery>({
        query: SEARCH_PRODUCT_GROUPS,
        variables: {
          search,
          ...mappedFilters,
          limit: 1000,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        tap((result) =>
          this.logger.debug(
            'ProductGroupService',
            'New data for Product Groups',
            result
          )
        ),
        map((result) => result.data.productGroups)
      );
  }

  // This function is used by the input-related-products shared component
  searchProductGroupsForRelated(
    search: string,
    filters: { brandId?: number } = {}
  ) {
    if (!search && !filters) {
      return EMPTY;
    }

    return this.apollo
      .watchQuery<ProductGroupsQuery>({
        query: SEARCH_PRODUCT_GROUPS_FOR_RELATED,
        variables: {
          search,
          ...filters,
          limit: 1000,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data.productGroups));
  }

  getProductGroups(limit: number, offset: number, full = false) {
    return this.apollo
      .query<ProductGroupsQuery>({
        query: full ? GET_PRODUCT_GROUPS : GET_PRODUCT_GROUPS_LITE,
        variables: {
          limit,
          offset,
        },
      })
      .pipe(map((result) => result.data.productGroups));
  }

  getProductGroup(groupId: number) {
    return this.apollo
      .query<ProductGroupQuery>({
        query: GET_PRODUCT_GROUP,
        variables: {
          groupId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((r) => new ProductGroup(r.data.productGroup)));
  }

  updateProductGroup(productGroup: ProductGroup) {
    if (productGroup.children && productGroup.children.length > 0) {
      productGroup.children.map((child) => {
        delete child.productMeta; // we don't want this to go to GQL, so delete it
      });
    }

    return this.apollo
      .mutate({
        mutation: UPDATE_PRODUCT_GROUP,
        variables: {
          productGroup,
        },
        errorPolicy: 'all',
      })
      .pipe(
        map((r: any) => {
          if (r.errors && r.errors.length > 0) {
            return r;
          }
          return new ProductGroup(r.data.updateProductGroup);
        })
      );
  }

  deleteProductGroup(productGroup: ProductGroup) {
    return this.apollo.mutate({
      mutation: DELETE_PRODUCT_GROUP,
      variables: {
        groupId: productGroup.groupId,
      },
    });
  }

  mergeProductGroups(destination, sources) {
    return new Promise(async (resolve, reject) => {
      // get the PGs in our sources array
      const groups = await this.apollo
        .query<ProductGroupsQuery>({
          query: GET_PRODUCT_GROUPS_FULL_BY_IDS,
          variables: {
            groupIds: sources.map((s) => s.groupId),
          },
        })
        .pipe(map((result) => result.data.productGroups))
        .toPromise();

      // then go through and move the children & delete the old PG
      for (const group of groups) {
        for (const child of group.children) {
          child.groupId = destination.groupId; // change the childs GroupID
          await this.productChildService.updateProductChild(child).toPromise(); // update the child
          // this.woosyncService.syncProduct(group); // send the old PG to resync (so it goes offline)
          await this.deleteProductGroup(group).toPromise(); // delete the old PG
        }
      }

      resolve(true);
    });
  }

  // GETTERS
  getSearchTerm() {
    return this.searchTerm;
  }

  getAsInput(group: ProductGroup): ProductGroup {
    return {
      groupId: group.groupId,
      title: group.title,
      description: group.description,
      brandId: group.brandId,
      gender: group.gender,
      categories: group.categories,
      images: group.images,
      imageDefaults: group.imageDefaults,
      video: group.video,
      highlights: group.highlights,
      species: group.species,
      relatedProducts: group.relatedProducts,
      hidePricingOnline: group.hidePricingOnline,
      notSoldOnline: group.notSoldOnline,
      pickupOnly: group.pickupOnly,
      deliveryAvailable: group.deliveryAvailable,
      deliveryExtraHandling: group.deliveryExtraHandling,
      globalPromotion: group.globalPromotion,
      keywords: group.keywords,
      featured: group.featured,
      featuredStartDate: group.featuredStartDate,
      featuredEndDate: group.featuredEndDate,
      featuredOrder: group.featuredOrder,
      customTabTitle: group.customTabTitle,
      customTabContent: group.customTabContent,
      publish: group.publish,
      publishSettings: group.publishSettings,
      limitedStockQuantity: group.limitedStockQuantity,
      purchaseLimit: group.purchaseLimit,
      excludedStates: group.excludedStates,
      children: group.children,
      requiresFraudCheck: group.requiresFraudCheck,
      taxCode: group.taxCode,
      prop65MessageType: group.prop65MessageType,
      chemicals: group.chemicals,
    };
  }

  searchProducts$(search: string, limit = 20) {
    return this.apollo
      .use('omniApi')
      .query<IGetProducts>({
        query: GET_PRODUCTS,
        fetchPolicy: 'network-only',
        variables: {
          config: { search, limit },
        },
      })
      .pipe(
        map((result) => result.data.getProducts),
        map((products) => products.map((product) => new Product(product)))
      );
  }

  // TIDY UP
  ngOnDestroy() {
    this.getProductGroups$.unsubscribe();
  }
}
