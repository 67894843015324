import gql from 'graphql-tag';
import { ProductChild } from '../models/product-child.model';
import {ProductMeta} from '../models/product-meta.model';

const PRODUCT_CHILD_FIELDS = `
  childId,
  groupId,
  itemNumber,
  sizeId,
  size {
    id,
    sizeName
  },
  colorId,
  color {
    id,
    name
  },
  image,
  publish,
  parent {
    title
  },
  productMeta {
    productsMaster,
    productsLocation,
    productsLocationSecondary,
    productsPim
  }
`;

export const GET_PRODUCT_CHILD = gql`
  query ProductChild($itemNumber: String!) {
    productChild(itemNumber: $itemNumber) {
      ${PRODUCT_CHILD_FIELDS}
    }
  }
`;

export const GET_PRODUCT_CHILDREN = gql`
  query ProductChildren($itemNumbers: [String]) {
    productChildren(itemNumbers: $itemNumbers) {
      childId
      groupId
      itemNumber
      image
      publish
    }
  }
`;

export const UPDATE_PRODUCT_CHILD = gql`
  mutation UpdateProductChild($productChild: ProductChildInput) {
    updateProductChild(productChild: $productChild) {
      ${PRODUCT_CHILD_FIELDS}
    }
  }
`;

export const DELETE_PRODUCT_CHILD = gql`
  mutation DeleteProductChild($childId: Int!) {
    deleteProductChild(childId: $childId)
  }
`;

export const SAVE_PIM_DATA = gql`
  mutation UpdatePIM($pim: ProductPIMInput) {
    updatePIM(pim: $pim)
  }
`;

export const GET_PRODUCT_METAS = gql`
  query ProductMetas($itemNumbers: [String!]) {
    productMetas(itemNumbers: $itemNumbers) {
      itemNumber
    }
  }
`

export interface ProductChildQuery {
  productChild: ProductChild;
}

export interface ProductChildrenQuery {
  productChildren: [ProductChild];
}

export interface ProductMetaQuery {
  productMetas: [ProductMeta];
}
