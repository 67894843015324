import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  private readonly _isOpen$ = new BehaviorSubject<boolean>(false);
  readonly isOpen$ = this._isOpen$.asObservable();

  opened = false;
  userNavOpened = false;
  secondaryNavOpened = false;
  secondaryNavChildren: any[] = [];
  contentNav = this.route.queryParamMap.pipe(
    map(params => params.get('showSideBar')),
    map(showSideBar => {
      if (showSideBar !== null) {
        return showSideBar === 'true' ? true : false;
      } else {
        return true;
      }
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(private route: ActivatedRoute) {}

  openNav = () => this.setOpened(true);
  closeNav = () => this.setOpened(false);
  toggleNav = () => this.setOpened(!this.opened);
  isOpen = () => this.opened;

  toggleUerNav = () => (this.userNavOpened = !this.userNavOpened);
  isUserNavOpen = () => this.userNavOpened;

  openSecondaryNav = (children: any[]) => this.setSecondaryNav(children);
  closeSecondaryNav = () => this.setSecondaryNav(null);
  isSecondaryNavOpen = () => this.secondaryNavOpened;
  getSecondaryNav = () => this.secondaryNavChildren;

  private setOpened(opened: boolean) {
    this._isOpen$.next(opened);
    this.userNavOpened = false;
    this.opened = opened;
  }

  private setSecondaryNav(children: any[]) {
    this.secondaryNavChildren = children;
    this.secondaryNavOpened = children ? true : false;
  }
}
