import gql from 'graphql-tag';
import { ProductSpecies } from '../models/product-species.model';

export const GET_PRODUCT_SPECIES = gql`
    {
        productSpecies {
            id,
            speciesName,
            wooId
        }
    }
`;

export interface ProductSpeciesQuery {
    productSpecies: ProductSpecies[];
  }