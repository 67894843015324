import { ApolloLink } from '@apollo/client';
import { Store } from '@ngrx/store';
import QueueLink from 'apollo-link-queue';
import * as uuid from 'uuid';
import { AppState } from '../app-store.module';
import { addQuery, removeQuery } from './store/actions/tracker-link.actions';

export class TrackerLink extends ApolloLink {
  constructor(
    private store: Store<AppState>,
    private offlineLink: QueueLink
  ) {
    super();
  }
  request(operation, forward) {
    if (forward === undefined) {
      return forward(operation);
    }

    const name: string = operation.operationName;
    const queryJSON: string = JSON.stringify(operation.query);
    const variablesJSON: string = JSON.stringify(operation.variables);
    const context = operation.getContext();
    const id = uuid.v4();

    if (!!context.queryId && !(this.offlineLink as any)?.isOpen) {
      const { queryId, serializationKey, optimisticResponse } = context;
      const contextJSON = JSON.stringify({
        queryId,
        serializationKey,
        optimisticResponse,
      });
      this.store.dispatch(
        addQuery({
          contextJSON,
          id,
          name,
          queryJSON,
          variablesJSON,
        })
      );
    }
    return forward(operation).map((data) => {
      // If we have a queryId, we can remove it because we were successful
      if (context.queryId) {
        this.store.dispatch(removeQuery(context.queryId));
      }

      return data;
    });
  }
}
