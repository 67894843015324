import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';
import { map, tap } from 'rxjs/operators';
import { EmailTemplate } from 'wilco-lib-models';
import {
  CREATE_EMAIL_TEMPLATE,
  CreateEmailTemplate,
  DELETE_EMAIL_TEMPLATE,
  DeleteEmailTemplate,
  GET_EMAIL_PREVIEW,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATES,
  GetEmailPreview,
  GetEmailTemplate,
  GetEmailTemplates,
  SEND_EMAIL_PREVIEW,
  SendEmailPreview,
  UPDATE_EMAIL_TEMPLATE,
  UpdateEmailTemplate,
} from '../../queries/content/email-template.graphql';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateService {
  constructor(
    private apollo: Apollo,
    private snackbar: MatSnackBar
  ) {}

  getEmailTemplate(id: number) {
    return this.apollo
      .use('omniApi')
      .query<GetEmailTemplate>({
        query: GET_EMAIL_TEMPLATE,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((res) => res.data.getEmailTemplate),
        map((result) => (result ? new EmailTemplate(result) : null))
      );
  }

  getEmailTemplates(name?: string) {
    return this.apollo
      .use('omniApi')
      .watchQuery<GetEmailTemplates>({
        query: GET_EMAIL_TEMPLATES,
        variables: {
          name,
        },
        fetchPolicy: 'network-only',
        pollInterval: 5000,
      })
      .valueChanges.pipe(
        map((res) => res.data.getEmailTemplates),
        map((results) =>
          results ? results.map((result) => new EmailTemplate(result)) : []
        )
      );
  }

  getEmailPreview(id: number) {
    return this.apollo
      .use('omniApi')
      .query<GetEmailPreview>({
        query: GET_EMAIL_PREVIEW,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => res.data.getEmailPreview));
  }

  createEmailTemplate(emailTemplate: Partial<EmailTemplate>) {
    return this.apollo
      .use('omniApi')
      .mutate<CreateEmailTemplate>({
        mutation: CREATE_EMAIL_TEMPLATE,
        variables: {
          emailTemplate,
        },
      })
      .pipe(map((res) => res.data.createEmailTemplate));
  }

  updateEmailTemplate(emailTemplate: EmailTemplate) {
    return this.apollo
      .use('omniApi')
      .mutate<UpdateEmailTemplate>({
        mutation: UPDATE_EMAIL_TEMPLATE,
        variables: {
          emailTemplate,
        },
      })
      .pipe(
        map((res) => res.data.updateEmailTemplate),
        tap(() =>
          this.snackbar.open(`Email template saved succuessfully`, 'CLOSE', {
            duration: 3000,
          })
        )
      );
  }

  sendEmailPreview(recipient: string, id: number) {
    return this.apollo
      .use('omniApi')
      .query<SendEmailPreview>({
        query: SEND_EMAIL_PREVIEW,
        variables: {
          recipient,
          id,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((res) => res.data.sendEmailPreview),
        tap(() =>
          this.snackbar.open(`Email preview sent to ${recipient}`, 'CLOSE', {
            duration: 3000,
          })
        )
      );
  }

  deleteEmailTemplate(id: number) {
    return this.apollo
      .use('omniApi')
      .mutate<DeleteEmailTemplate>({
        mutation: DELETE_EMAIL_TEMPLATE,
        variables: {
          id,
        },
      })
      .pipe(map((res) => res.data.deleteEmailTemplate));
  }
}
