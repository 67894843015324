import gql from 'graphql-tag';
import { EmailTemplate } from 'wilco-lib-models';

export const GET_EMAIL_TEMPLATE = gql`
  query GetEmailTemplate($id: Int!) {
    getEmailTemplate(id: $id) {
      id
      name
      subject
      contentObject
    }
  }
`;

export const GET_EMAIL_TEMPLATES = gql`
  query GetEmailTemplates($name: String) {
    getEmailTemplates(name: $name) {
      id
      name
      subject
    }
  }
`;

export const GET_EMAIL_PREVIEW = gql`
  query GetEmailPreview($id: Int!) {
    getEmailPreview(id: $id)
  }
`;

export const CREATE_EMAIL_TEMPLATE = gql`
  mutation CreateEmailTemplate($emailTemplate: EmailTemplateInput!) {
    createEmailTemplate(emailTemplate: $emailTemplate)
  }
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateEmailTemplate($emailTemplate: EmailTemplateInput!) {
    updateEmailTemplate(emailTemplate: $emailTemplate)
  }
`;

export const DELETE_EMAIL_TEMPLATE = gql`
  mutation DeleteEmailTemplate($id: Int!) {
    deleteEmailTemplate(id: $id)
  }
`;

export const SEND_EMAIL_PREVIEW = gql`
  query sendEmailPreview($recipient: String!, $id: Int!) {
    sendEmailPreview(recipient: $recipient, id: $id)
  }
`;

export interface GetEmailTemplate {
  getEmailTemplate: EmailTemplate;
}

export interface GetEmailTemplates {
  getEmailTemplates: EmailTemplate[];
}
export interface GetEmailPreview {
  getEmailPreview: string;
}

export interface CreateEmailTemplate {
  createEmailTemplate: number;
}

export interface UpdateEmailTemplate {
  updateEmailTemplate: string;
}
export interface SendEmailPreview {
  sendEmailPreview: string;
}

export interface DeleteEmailTemplate {
  deleteEmailTemplate: string;
}
