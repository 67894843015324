import gql from 'graphql-tag';
import { ProductGroup } from '../models';

const PRODUCT_GROUP_FIELDS_LITE = `
    groupId,
    title,
    images,
    children {
      childId,
      itemNumber,
      image
    }
`;

const PRODUCT_GROUP_FIELDS = `
  groupId,
  title,
  description,
  brandId,
  brand {
    id,
    brandName
  },
  gender,
  categories {
    id,
    name
  },
  images,
  imageDefaults,
  video,
  highlights,
  species {
    id,
    speciesName
  },
  hidePricingOnline,
  notSoldOnline,
  pickupOnly,
  limitedStockQuantity,
  deliveryAvailable,
  deliveryExtraHandling,
  globalPromotion,
  keywords,
  featured,
  featuredStartDate,
  featuredEndDate,
  featuredOrder,
  customTabTitle,
  customTabContent,
  publish,
  publishSettings,
  purchaseLimit
  excludedStates,
  requiresFraudCheck,
  taxCode,
  prop65MessageType,
  chemicals {
    id,
    chemicalName,
    typeOfToxicity
  }
  prop65Warning {
    id,
    title,
    message,
    args,
    type,
    warningArgs
  }
  productWarnings {
    id,
    title,
    message,
    args,
    type,
    warningArgs
  },
  createdAt,
  updatedAt,
  children {
    childId,
    groupId,
    itemNumber,
    sizeId,
    size {
      id,
      sizeName
    },
    colorId,
    color {
      id,
      name
    },
    image,
    publish,
    productMeta {
      productsMaster,
      productsLocation,
      productsLocationSecondary
    }
  }
  relatedProducts {
    groupId
    title
    woosync {
      wooId
    }
  }
`;

export const SEARCH_PRODUCT_GROUPS = gql`
  query ProductGroups(
    $search: String,
    $brandId: Int,
    $limit: Int,
    $offset: Int,
    $fineline: [String],
    $exclude: [String],
    $include: [String],
    $showOnly: String,
    $sortBy: String
  ) {
    productGroups(
      search: $search,
      brandId: $brandId,
      limit: $limit,
      offset: $offset,
      fineline: $fineline,
      exclude: $exclude,
      include: $include,
      showOnly: $showOnly,
      sortBy: $sortBy
    ) {
      ${PRODUCT_GROUP_FIELDS_LITE}
    }
  }
`;

export const SEARCH_PRODUCT_GROUPS_FOR_RELATED = gql`
  query ProductGroups($search: String, $brandId: Int) {
    productGroups(search: $search, brandId: $brandId) {
      groupId
      title
      images
      woosync {
        wooId
      }
    }
  }
`;

export const GET_PRODUCT_GROUPS = gql`
  query ProductGroups($limit: Int, $offset: Int) {
    productGroups(limit: $limit, offset: $offset) {
      ${PRODUCT_GROUP_FIELDS},
      woosync {
        groupId,
        wooId,
      }
    }
  }
`;
export const GET_PRODUCT_GROUPS_LITE = gql`
  query ProductGroups($limit: Int, $offset: Int) {
    productGroups(limit: $limit, offset: $offset) {
      groupId
    }
  }
`;

export const GET_PRODUCT_GROUPS_BY_IDS = gql`
  query ProductGroups($groupIds: [Int]) {
    productGroups(groupIds: $groupIds) {
      ${PRODUCT_GROUP_FIELDS_LITE}
    }
  }
`;

export const GET_PRODUCT_GROUPS_FULL_BY_IDS = gql`
  query ProductGroups($groupIds: [Int]) {
    productGroups(groupIds: $groupIds) {
      ${PRODUCT_GROUP_FIELDS}
    }
  }
`;

export const GET_PRODUCT_GROUPS_FEATURED = gql`
  {
    productGroups(featured: true) {
      ${PRODUCT_GROUP_FIELDS}
    }
  }
`;

export const GET_PRODUCT_GROUPS_GLOBAL_PROMOTION = gql`
  {
    productGroups(globalPromotion: true, limit: 20000) {
      groupId
    }
  }
`;

export const GET_PRODUCT_GROUP = gql`
  query ProductGroup($groupId: Int!) {
    productGroup(groupId: $groupId) {
      ${PRODUCT_GROUP_FIELDS}
    }
  }
`;

export const UPDATE_PRODUCT_GROUP = gql`
  mutation UpdateProductGroup($productGroup: UpdateProductGroupInput) {
    updateProductGroup(productGroup: $productGroup) {
      ${PRODUCT_GROUP_FIELDS}
      relatedProducts {
        groupId
      }
    }
  }
`;

export const DELETE_PRODUCT_GROUP = gql`
  mutation DeleteProductGroup($groupId: Int!) {
    deleteProductGroup(groupId: $groupId)
  }
`;

export interface ProductGroupsQuery {
  productGroups: ProductGroup[];
}

export interface ProductGroupQuery {
  productGroup: ProductGroup;
}
