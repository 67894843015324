import { AppState } from '@app/shared/stores/app-store.module';
import { getRouterParam } from '@app/shared/stores/router/actions/router.action';
import { createAction, createSelector } from '@ngrx/store';
import { Item, Notification, Product, QuantityBreak } from 'wilco-lib-models';

// Selectors
export const selectItemId = createSelector(
  getRouterParam('itemId'),
  (id: string) => id
);

export const selectQuantityBreak = createSelector(
  (state: AppState) => state.productStore,
  (productStore: AppState['productStore']) => productStore.quantityBreak
);

// Actions
export const getItem = createAction('[Item] Get Item', (product: Product) => ({
  product,
}));

export const fetchQuantityBreak = createAction(
  '[Item] Fetch Quantity Break',
  (id: number) => ({ id })
);

export const getQuantityBreak = createAction(
  '[Item] Get Quantity Break',
  (quantityBreak: QuantityBreak) => ({ quantityBreak })
);

export const createItem = createAction(
  '[Item] Creating Item',
  (productId: number, itemNumber: string) => ({ productId, itemNumber })
);

export const createItemSuccess = createAction(
  '[Item] Item Created Successfully'
);

export const updateItem = createAction(
  '[Item] Updating Item',
  (productId: number, item: Partial<Item>, notification: Notification) => ({
    productId,
    item,
    notification,
  })
);

export const updateItemSuccess = createAction(
  '[Item] Item Updated Successfully'
);

export const deleteItem = createAction(
  '[Item] Deleting Item',
  (productId: number, itemNumber: string) => ({ productId, itemNumber })
);

export const deleteItemSuccess = createAction(
  '[Item] Item Deleted Successfully'
);
