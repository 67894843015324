import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { Apollo } from 'apollo-angular';
import { Auth0Lock } from 'auth0-lock';
import {
  BehaviorSubject,
  Observable,
  Subject,
  from,
  fromEvent,
  merge,
  timer,
} from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AuthUser } from 'wilco-lib-models';
import { GET_USERS, IGetAuthUsers } from '../queries/auth.graphql';
import { LoggerService } from '../services/logger.service';
import { AuthState, auth0Options } from './auth-lock.config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _lock$ = new BehaviorSubject<typeof Auth0Lock>(null);
  readonly lock$ = this._lock$.asObservable();

  private readonly _initalize$ = new Subject<void>();
  private readonly initalize$ = this._initalize$
    .asObservable()
    .pipe(map(() => this.initialize()));

  private readonly _renewSession$ = new BehaviorSubject<Observable<number>>(
    null
  );
  private readonly renewSession$ = this._renewSession$.asObservable().pipe(
    filter((timer) => !!timer),
    switchMap((timer) => timer.pipe(switchMap(() => from(this.renewTokens()))))
  );

  /**
   * Format our events
   */
  private lockEvents$ = this.lock$.pipe(
    filter((lock) => !!lock),
    switchMap((lock) => this.authEvents$(lock))
  );

  /**
   * Every event returns the same shape of data that we want, so effectively we will
   * always end up with our Auth state regardless of what event triggers it. Doing it
   * this way enables us to use computed values instead of digging into localstorage
   * everytime to read something. Now if we are authenticated, all the data we need
   * is here.
   */
  readonly state$: Observable<AuthState> = merge(
    this.lockEvents$,
    this.renewSession$, // When our renewal timer goes off
    this.initalize$ // When we log out
  ).pipe(
    startWith(this.initialize()),
    map((state) => {
      const isMichael = state.userName === 'Michael Naegeli';
      const isSalem = state.userName === 'Salem Staff';

      /**
       * Helper functions to determine if we are authenticated/ have permissions
       */
      const isAuthenticated = () =>
        state.idToken ? !this.jwtHelper.isTokenExpired(state.idToken) : false;
      const hasPermission = (scope: string) => !!state.permissions.get(scope);

      if (isAuthenticated()) {
        this.scheduleRenewal(state.idToken);
      }

      return { ...state, isMichael, isSalem, isAuthenticated, hasPermission };
    }),
    tap((state) => {
      if (state.action === 'auth') {
        const redirectUrl = this.route.snapshot.queryParams?.redirectUrl;
        const route =
          !!redirectUrl && redirectUrl.length > 1
            ? this.route.snapshot.queryParams?.redirectUrl
            : state.defaultRoute;

        this.router.navigate([route ? route : 'dashboard'], {
          queryParams: { redirectUrl: null },
          queryParamsHandling: 'merge',
        });
      }
    }),
    shareReplay(1)
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private jwtHelper: JwtHelperService,
    private apollo: Apollo
  ) {
    this.getLock();
  }

  /**
   * Look into localstorage and get everything we need to hydrate our Auth State
   */
  private initialize() {
    const idToken = localStorage.getItem('id_token') ?? null;
    const accessToken = localStorage.getItem('access_token');

    // Decode our token
    const token = this.jwtHelper.decodeToken(accessToken);

    // Get our userMetadata from our token
    let {
      storeID: storeId,
      userName,
      defaultRoute,
    } = token?.userMetadata ?? {};
    storeId = storeId ? parseInt(storeId, 10) : null;

    // Get our permissions from our token
    const permissions = new Map(
      token?.permissions ? token.permissions.map((key) => [key, true]) : []
    );

    return {
      action: 'init',
      idToken,
      storeId,
      permissions,
      userName,
      defaultRoute: defaultRoute ?? '/dashboard',
    };
  }

  private storeUserInfo(authResult: AuthResult, action: string) {
    const { idToken, accessToken, expiresIn } = authResult;
    const expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());

    // Decode our token
    const token = this.jwtHelper.decodeToken(accessToken);

    // Get our userMetadata from our token
    const {
      storeID: storeId,
      userLevel,
      userName,
      defaultRoute,
    } = token?.userMetadata ?? {};

    // Get our permissions from our token
    const permissions = new Map(
      token?.permissions ? token.permissions.map((key) => [key, true]) : []
    );

    // access token
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('expires_at', expiresAt);

    // id token
    localStorage.setItem('id_token', idToken);

    // Old Back40 Compatibility
    localStorage.setItem('storeId', storeId);
    localStorage.setItem('userLevel', userLevel);
    localStorage.setItem('userName', userName);

    return {
      action,
      storeId:
        storeId && typeof storeId === 'string' ? parseInt(storeId) : storeId,
      idToken,
      permissions,
      userName,
      defaultRoute,
    };
  }

  /**
   * Schedule a time to renew our token
   */
  private scheduleRenewal(token: string) {
    const expiresAt = +this.jwtHelper.getTokenExpirationDate(token);
    const TIME_OUT = Math.max(1, expiresAt - Date.now());

    this._renewSession$.next(timer(TIME_OUT));

    this.logger.debug('AuthService', 'Created new token renewal subscription');
  }

  /**
   * Wrapping this in a promise because callbacks are gross
   */
  private getUserInfo(authResult: AuthResult, action: string): Promise<any> {
    return new Promise((res, rej) => {
      const lock = this.getLock();
      lock.getUserInfo(authResult.accessToken, (authError) => {
        if (authError) {
          rej(authError.error);
        }

        /**
         * Store our latest user info in localStorage and get the values
         * that we care about.
         */
        res(this.storeUserInfo(authResult, action));
      });
    });
  }

  /**
   * Wraps checkSession callback into a promise
   */
  private renewTokens(): Promise<any> {
    return new Promise((res, rej) => {
      const lock = this.getLock();
      lock.checkSession({}, async (err, authResult) => {
        if (err) {
          this.logger.warn('AuthService', err);
          rej(err);
        } else {
          const results = await this.getUserInfo(authResult, 'renew');
          this.logger.debug('AuthService', 'User token successfully renewed');
          res(results);
        }
      });
    });
  }

  /**
   * Returns a lock if one already exists, otherwise it creates a lock and
   * returns that. This may seem contrived, however we only really need a
   * lock if we are logging in or out. When we create a lock we make a call
   * to Auth0 which can add 500-1000ms to our load times. Best to only do
   * that if we need it.
   */
  private getLock() {
    if (!this._lock$.value) {
      this._lock$.next(
        new Auth0Lock(
          'PILub0nXdeUTCAMWc7BzVaSyWpAH54jW',
          'login.back40.cloud',
          auth0Options
        )
      );
    }

    return this._lock$.value;
  }

  public login(): void {
    const lock = this.getLock();

    lock.show();
  }

  public logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('id_token');

    // old Back40 compatiibility
    localStorage.removeItem('storeId');
    localStorage.removeItem('userLevel');
    localStorage.removeItem('userName');

    // this._initalize$.next();

    const lock = this.getLock();

    lock.logout({
      returnTo: environment.host + '/auth/login',
    });
  }

  /**
   * Deprecated function. Can use function through state object.
   *
   * @deprecated
   */
  async hasPermission(scope: string) {
    return await this.state$
      .pipe(
        map((state) => state.hasPermission(scope)),
        take(1)
      )
      .toPromise();
  }

  /**
   * Create an Observable to listen for events on our lock.
   */
  private authEvents$(lock) {
    return merge(
      fromEvent(lock, 'authenticated'),
      fromEvent(lock, 'authorization_error').pipe(
        tap((error) =>
          this.logger.warn('AuthService', 'authorization error', error)
        ),
        filter(() => false) // Not doing anything with this event currently
      )
    ).pipe(
      switchMap((authResult: AuthResult) =>
        from(this.getUserInfo(authResult, 'auth'))
      )
    );
  }

  getCurrentUser$() {
    return this.state$
      .pipe(
        map(({ userName }) => userName),
        take(1)
      )
      .toPromise();
  }

  getUsers$(name = '') {
    return this.apollo
      .use('omniApi')
      .query<IGetAuthUsers>({ query: GET_USERS, variables: { name } })
      .pipe(
        map((result) => result.data.getUsers.map((user) => new AuthUser(user)))
      );
  }
}
