import { ProductSpecies } from './product-species.model';
import { Image } from './image.model';
import { ProductCategory } from './product-category.model';
import { ProductChild } from './product-child.model';
import { WoosyncJob } from './woosync.model';
import { ProductWarning } from './product-warning.model';
import { Chemical } from './chemical.model';

export interface ProductGroupImageDefaults {
  sizeId: number | 'any';
  colorId: number | 'any';
  image: Image;
}

export class ProductGroup {
  groupId: number;
  title: string;
  description: string;
  images: Image[];
  imageDefaults: ProductGroupImageDefaults[];
  video: string;
  brandId: number;
  gender: string;
  highlights: string[];
  relatedProducts: string[];
  hidePricingOnline: boolean;
  notSoldOnline: boolean;
  pickupOnly: boolean;
  deliveryAvailable: number;
  deliveryExtraHandling: boolean;
  globalPromotion: boolean;
  keywords: string[];
  featured: boolean;
  featuredStartDate: string;
  featuredEndDate: string;
  featuredOrder: number;
  customTabTitle: string;
  customTabContent: string;
  publish: boolean;
  publishSettings: any;
  limitedStockQuantity: number;
  purchaseLimit: number;
  prop65MessageType: string;
  prop65Warning?: ProductWarning;
  excludedStates: string[];
  productWarnings?: ProductWarning[];
  species: ProductSpecies[];
  categories: ProductCategory[];
  children: ProductChild[];
  woosync?: WoosyncJob;
  chemicals: Chemical[];
  requiresFraudCheck: number;
  taxCode: string;

  constructor(data: any) {
    Object.assign(this, data);

    this.images = this.images || [];
    this.imageDefaults = this.imageDefaults || [];
    this.highlights = this.highlights || [];
    this.relatedProducts = this.relatedProducts || [];
    this.species = this.species || [];
    this.categories = this.categories || [];
    this.children = this.children || [];
    this.hidePricingOnline = this.hidePricingOnline || false;
    this.notSoldOnline = this.notSoldOnline || false;
    this.pickupOnly = this.pickupOnly || false;
    this.deliveryAvailable = this.deliveryAvailable || 3;
    this.deliveryExtraHandling = this.deliveryExtraHandling || false;
    this.globalPromotion = this.globalPromotion || false;
    this.keywords = this.keywords || [];
    this.featured = this.featured || false;
    this.publish = typeof this.publish !== 'undefined' ? this.publish : true;
    this.excludedStates = this.excludedStates || [];
    this.productWarnings = this.productWarnings || [];
    this.requiresFraudCheck = this.requiresFraudCheck;
  }
}
