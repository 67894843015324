import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, combineLatest, concat, of } from 'rxjs';
import { map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Customer, ProfileSource } from 'wilco-lib-models';
import {
  CustomerQuery,
  CustomersQuery,
  GET_CUSTOMER,
  GET_CUSTOMERS_LITE,
  GET_PROFILE,
  GET_PROFILES,
  IGetProfile,
  IGetProfiles,
  LINK_PROFILE,
  UNLINK_PROFILE,
  UPDATE_CUSTOMER,
} from '../queries/customer.graphql';
@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private readonly searchParams = new BehaviorSubject<{
    search?: string;
  }>({});

  private _customerUpdated = new BehaviorSubject<void>(undefined);
  customerUpdated$ = this._customerUpdated.asObservable();

  private _customers$ = combineLatest([this.searchParams.asObservable()]).pipe(
    switchMap(([search]) => {
      if (!search?.search) return of([]);

      return this._searchAccounts(search);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _profiles$ = combineLatest([this.searchParams.asObservable()]).pipe(
    switchMap(([search]) =>
      concat(
        this._searchProfiles({ ...search, type: ProfileSource.ECOM }),
        this._searchProfiles({ ...search, type: ProfileSource.WOO })
      )
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  searchResults$ = combineLatest([this._customers$, this._profiles$]).pipe(
    mergeMap(([customers, profiles]) => {
      return of({
        customers,
        profiles,
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(private apollo: Apollo) {}

  getCustomer(id: string) {
    return this.apollo
      .use('omniApi')
      .watchQuery<CustomerQuery>({
        query: GET_CUSTOMER,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data.customer));
  }

  searchCustomers(variables: { search?: string }) {
    this.searchParams.next(variables);
  }

  updateCustomer(customer: Customer) {
    return this.apollo
      .use('omniApi')
      .mutate({
        mutation: UPDATE_CUSTOMER,
        variables: {
          customer,
        },
      })
      .pipe(tap(() => this._customerUpdated.next()));
  }

  searchProfiles(search: string, type: string) {
    return this._searchProfiles({ search, type });
  }

  searchAccounts(search: string) {
    return this._searchAccounts({ search });
  }

  getProfile(id: string) {
    return this.apollo
      .use('omniApi')
      .query<IGetProfile>({
        query: GET_PROFILE,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data.profile));
  }

  linkProfile(
    externalId: string,
    externalSource: string,
    customerNumber: string
  ) {
    return this.apollo
      .use('omniApi')
      .mutate({
        mutation: LINK_PROFILE,
        variables: {
          externalId,
          externalSource,
          customerNumber,
        },
      })
      .pipe(tap(() => this._customerUpdated.next()));
  }

  unlinkProfile(externalId: string, externalSource: string) {
    return this.apollo
      .use('omniApi')
      .mutate({
        mutation: UNLINK_PROFILE,
        variables: {
          externalId,
          externalSource,
        },
      })
      .pipe(tap(() => this._customerUpdated.next()));
  }

  private _searchAccounts(search) {
    return this.apollo
      .use('omniApi')
      .watchQuery<CustomersQuery>({
        query: GET_CUSTOMERS_LITE,
        fetchPolicy: 'network-only',
        variables: { payload: { ...search } },
      })
      .valueChanges.pipe(map((result) => result.data.customers));
  }

  private _searchProfiles(payload) {
    return this.apollo
      .use('omniApi')
      .watchQuery<IGetProfiles>({
        query: GET_PROFILES,
        fetchPolicy: 'network-only',
        variables: {
          payload,
        },
      })
      .valueChanges.pipe(map((result) => result.data.profiles));
  }
}
