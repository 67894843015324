import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Subject, empty, from } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { ProductCategory } from '../models';
import {
  DELETE_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORIES_GROUPED,
  GET_PRODUCT_CATEGORY,
  ProductCategoriesQuery,
  ProductCategoryQuery,
  UPDATE_PRODUCT_CATEGORY,
} from '../queries/product-category.graphql';
import { LoggerService } from './logger.service';
import { WooApiService } from './woo-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductCategoryService implements OnDestroy {
  private initalizing: Promise<ProductCategory[]>;
  private productCategories: ProductCategory[] = [];
  productCategoriesChanged$ = new BehaviorSubject<ProductCategory[]>([]);
  readonly googleTaxonomies$ = this._getGoogleTaxonomies$().pipe(
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  protected onDestroy = new Subject<void>();

  constructor(
    private apollo: Apollo,
    private logger: LoggerService,
    private http: HttpClient,
    private wooApiService: WooApiService,
    private snackbar: MatSnackBar
  ) {}

  initCategories() {
    this.initalizing = this.apollo
      .query<ProductCategoriesQuery>({
        query: GET_PRODUCT_CATEGORIES_GROUPED,
        fetchPolicy: 'network-only',
      })
      .toPromise()
      .then(result => {
        this.productCategories = result.data.productCategories;
        this.productCategoriesChanged$.next(this.productCategories.slice());

        this.logger.debug(
          'ProductCategorieservice',
          'productCategories set to',
          this.productCategories
        );

        return this.productCategories;
      });

    return this.initalizing;
  }

  async getCategories() {
    await this.initalizing;
    return this.productCategories.slice();
  }

  getCategoryById(id: number) {
    return this.apollo
      .query<ProductCategoryQuery>({
        query: GET_PRODUCT_CATEGORY,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .toPromise()
      .then(result => {
        const category = result.data.productCategory;

        // do some cleanup
        if (category?.image) {
          const image = category.image as any;
          if (typeof image.imageSource === 'string') {
            category.image = {
              wooId: image.attachmentID,
              image: image.imageSource,
              thumbnail: image.imageSource,
            };
          }
        }
        return category;
      });
  }

  private _getGoogleTaxonomies$() {
    return this.http
      .get('assets/taxonomy-with-ids.en-US.txt', { responseType: 'text' })
      .pipe(
        map(result => {
          let formattedData = {};
          let data = result.split('\n'); // split into string[];
          data.shift(); // get rid of the 1st row being a label
          data.pop(); // get rid of the last row being empty
          data = data.map(r => r.split(' - ')[1]); // remove the ID
          // flatten to an array (all keys, no values)
          data.map(r => {
            let cursor = formattedData;
            r.split(' > ').map(c => {
              if (!cursor[c])
                cursor[c] = {
                  name: c,
                  children: {},
                };
              cursor = cursor[c].children;
            });
          });
          function dekey(obj: any) {
            return {
              name: obj.name,
              children: Object.values(obj.children).map(c => dekey(c)),
            };
          }
          formattedData = Object.values(formattedData).map(d => dekey(d));
          return formattedData;
        })
      );
  }

  updateProductCategory(
    productCategory: ProductCategory
  ): Promise<ProductCategory> {
    return new Promise(async (resolve, reject) => {
      // send to Woo and get the wooId in response
      const wooId: any = await this.updateWooCategory(productCategory);
      productCategory.wooId = wooId;

      // delete parent, we don't want it to go to GQL
      delete productCategory.parent;
      if (productCategory.finelines) {
        productCategory.finelines = productCategory.finelines.map(f => {
          delete f.products;
          return f;
        });
      }

      // delete children, we don't want to affect them either
      delete productCategory.children;

      this.apollo
        .mutate({
          mutation: UPDATE_PRODUCT_CATEGORY,
          variables: {
            productCategory,
          },
        })
        .pipe(
          map(async (r: any) => {
            if (r.errors && r.errors.length > 0) {
              return r;
            }
            return r.data.updateProductCategory;
          })
        )
        .toPromise()
        .then(resp => {
          this.initCategories();
          resolve(resp);
        });
    });
  }

  deleteProductCategory(productCategory: ProductCategory) {
    // delete ecom category
    this.deleteWooCategory(productCategory);

    return this.apollo
      .mutate({
        mutation: DELETE_PRODUCT_CATEGORY,
        variables: {
          id: productCategory.id,
        },
      })
      .toPromise()
      .then(resp => {
        this.initCategories();
        return resp;
      });
  }

  updateWooCategory(productCategory: ProductCategory) {
    return new Promise(async (resolve, reject) => {
      let parent = null;
      if (productCategory.parentId && productCategory.parent) {
        if (!productCategory.parent.wooId) {
          return reject('Parent is missing an ID');
        } else {
          parent = productCategory.parent.wooId.toString();
        }
      }

      const formData = new FormData();
      formData.append('action', 'category');
      formData.append('name', productCategory.name);
      formData.append('parent', parent);
      formData.append(
        'wooId',
        productCategory.wooId ? productCategory.wooId.toString() : null
      );
      formData.append(
        'image',
        productCategory.image ? productCategory.image.wooId.toString() : null
      );
      formData.append('googleTaxonomy', productCategory.googleTaxonomy);
      formData.append('isFeatured', productCategory.isFeatured.toString());
      formData.append('startDate', productCategory.startDate);
      formData.append('endDate', productCategory.endDate);
      formData.append(
        'taxCode',
        productCategory.finelines.map(f => f.taxCode).find(f => !!f) || null
      );

      await this.wooApiService
        .makeRequest('product', formData)
        .toPromise()
        .then((res: any) => {
          if (res.error) {
            this.logger.error(
              'ProductCategoryService',
              'Woo replied with an error',
              res.error
            );
            resolve(null);
          } else {
            resolve(res.body.data.wooId);
          }
        })
        .catch(err => {
          this.logger.error(
            'ProductCategoryService',
            'Woo replied with an error',
            err
          );
          resolve(null);
        });
    });
  }

  deleteWooCategory(productCategory: ProductCategory) {
    return new Promise(async (resolve, reject) => {
      if (!productCategory.wooId) {
        // no wooId, skip
        return resolve(true);
      }

      const formData = new FormData();
      formData.append('action', 'deletecategory');
      formData.append('wooId', productCategory.wooId.toString());

      await this.wooApiService
        .makeRequest('product', formData)
        .toPromise()
        .then((res: any) => {
          if (res.error) {
            this.logger.error(
              'ProductCategoryService',
              'Woo replied with an error',
              res.error
            );
            resolve(null);
          } else {
            resolve(res.body.data.success);
          }
        })
        .catch(err => {
          this.logger.error(
            'ProductCategoryService',
            'Woo replied with an error',
            err
          );
          resolve(null);
        });
    });
  }

  async syncAllCategories() {
    await this.initCategories();

    const syncCats = async (level = 0) => {
      // reducer function to return
      const reduceCats = cats =>
        cats.reduce(
          (acc, c) => (c.children ? acc.concat(c.children) : acc),
          []
        );

      let cats = this.productCategories;

      for (let i = 1; i <= level; i++) {
        cats = reduceCats(cats);
      }

      const ids = cats.map(c => c.id); // these are what we will sync in this recursion
      console.log(`Level ${level} - syncing ${ids.length} categories`);

      await from(ids)
        .pipe(
          mergeMap((id, _) => {
            return this.getCategoryById(id).then(cat => {
              return this.updateProductCategory(cat).then(_ => empty());
            });
          }, 10)
        )
        .toPromise();

      return ids.length;
    };

    let level = 0;
    while (true) {
      const result = await syncCats(level);
      level++;

      if (result === 0) break;
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
