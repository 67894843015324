import { OrderChild } from './order-child.model';
import { OrderShipping } from './order-shipping.model';
import { OrderItem } from '.';
import { OrderNote } from './order-note.model';

export class Order {
  id: number;
  orderNumber: string;
  externalId: string;
  externalSource: string;
  orderDate: number;
  orderStatus: string;
  customerFirstName: string;
  customerLastName: string;
  customerAccountNumber: string;
  customerPhone: string;
  customerEmail: string;
  customerIp: string;
  billingStreetAddress1: string;
  billingStreetAddress2: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  shippingStreetAddress1: string;
  shippingStreetAddress2: string;
  shippingCity: string;
  shippingState: string;
  shippingZip: string;
  alternatePickupName: string;
  PoNumber: string;
  paymentMethod: string;
  paymentTransactionId: string;
  orderTax: number;
  orderTotal: number;
  savingsTotal: number;
  couponTotal: number;
  couponTax: number;
  couponUsed: string;
  antiFraud: any;
  refunds: any[];
  notes: OrderNote[];
  completedAt: number;
  children?: OrderChild[];
  shippingMethods?: OrderShipping[];
  createdAt: string;
  updatedAt: string;

  constructor(data: any) {
    Object.assign(this, data);

    this.refunds = this.refunds || [];
    this.notes = this.notes || [];
    this.children = this.children || [];
    this.shippingMethods = this.shippingMethods || [];
  }
}
