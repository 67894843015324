import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { ProductChild } from '../models/product-child.model';
import {
  DELETE_PRODUCT_CHILD,
  GET_PRODUCT_CHILD,
  GET_PRODUCT_CHILDREN,
  GET_PRODUCT_METAS,
  ProductChildQuery,
  ProductChildrenQuery,
  ProductMetaQuery,
  SAVE_PIM_DATA,
  UPDATE_PRODUCT_CHILD,
} from '../queries/product-child.graphql';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class ProductChildService {
  constructor(private apollo: Apollo, private logger: LoggerService) {}

  getProductChild(itemNumber: string) {
    return this.apollo
      .query<ProductChildQuery>({
        query: GET_PRODUCT_CHILD,
        variables: {
          itemNumber,
        },
      })
      .pipe(map(r => new ProductChild(r.data.productChild)));
  }

  getProductChildren(itemNumbers: string[]) {
    return this.apollo
      .query<ProductChildrenQuery>({
        query: GET_PRODUCT_CHILDREN,
        variables: {
          itemNumbers,
        },
      })
      .pipe(map(r => r.data.productChildren.map(p => new ProductChild(p))));
  }

  updateProductChild(productChild: ProductChild) {
    delete productChild.productMeta; // we don't want this to go to GQL, so delete it
    delete productChild.parent; // GQL doesn't accept this

    return this.apollo
      .mutate({
        mutation: UPDATE_PRODUCT_CHILD,
        variables: {
          productChild,
        },
      })
      .pipe(map((r: any) => new ProductChild(r.data.updateProductChild)));
  }

  deleteProductChild(childId: number) {
    return this.apollo.mutate({
      mutation: DELETE_PRODUCT_CHILD,
      variables: {
        childId,
      },
    });
  }

  savePimData(itemNumber, pimData) {
    return this.apollo.mutate({
      mutation: SAVE_PIM_DATA,
      variables: {
        pim: {
          itemNumber,
          productsPim: pimData,
        },
      },
    });
  }

  getProductMetaByItemNumbers(itemNumbers: Array<string>) {
    return this.apollo.query<ProductMetaQuery>({
      query: GET_PRODUCT_METAS,
      variables: {
        itemNumbers,
      },
    });
  }
}
