export interface OrderDelivery {
  id: number;
  orderChildId: number;
  truckId: number;
  deliveryStatus: string;
  deliveryDateTime: number;
  unloadTime: number;
  capacityRequired: number;
  orderItems: any[];
  loadedAt?: number;
  approvedAt?: number;
  completedAt?: number;
}
