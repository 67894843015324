import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { empty } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuantityBreak, QuantityBreakInput } from 'wilco-lib-models';
import {
  GET_QUANTITY_BREAKS,
  GET_QUANTITY_BREAK_CODE,
  QuantityBreakCodeQuery,
  QuantityBreakResultsQuery,
  UpdateQuantityBreaksQuery,
  UPDATE_QUANTITY_BREAKS,
} from '../queries/quantity-break-code.graphql';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class QuantityBreakCodeService {
  constructor(private apollo: Apollo, private logger: LoggerService) {}

  getQuantityBreakByCode(code: number) {
    if (!code) {
      return empty();
    }

    return this.apollo
      .query<QuantityBreakCodeQuery>({
        query: GET_QUANTITY_BREAK_CODE,
        variables: {
          code,
        },
      })
      .pipe(map(r => r.data.quantityBreakCode));
  }

  getQuantityBreaks() {
    return this.apollo
      .use('omniApi')
      .query<QuantityBreakResultsQuery>({
        query: GET_QUANTITY_BREAKS,
      })
      .pipe(map(r => r.data.getQuantityBreaks.map(q => new QuantityBreak(q))));
  }

  updateQuantityBreaks(quantityBreaks: QuantityBreak[]) {
    const qbc = quantityBreaks.map(q => new QuantityBreakInput(q.toObject()));
    return this.apollo
      .use('omniApi')
      .mutate<UpdateQuantityBreaksQuery>({
        mutation: UPDATE_QUANTITY_BREAKS,
        variables: {
          quantityBreaks: qbc,
        },
      })
      .pipe(map(result => result.data.updateQuantityBreaks))
      .toPromise();
  }
}
