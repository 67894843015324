import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, switchMap } from 'rxjs/operators';
import {
  CREATE_STORE,
  DELETE_STORE,
  GET_FULFILLMENT_TIME,
  GET_OPEN_STORE_ORDERS,
  GET_STORE,
  GET_STORES,
  GET_STORE_ANALYTICS,
  GetFulfillmentTime,
  GetOpenStoreOrders,
  GetStoreAnalytics,
  ICreateStore,
  IDeleteStore,
  IGetStore,
  IGetStores,
  IUpdateStore,
  UPDATE_STORE,
} from '../queries/store.graphql';

import { Store } from 'wilco-lib-models';
import { AuthService } from '../auth';
import { APIConnector } from './api.connector';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  stores: Store[];

  constructor(
    private authService: AuthService,
    private apollo: Apollo,
    private connector: APIConnector
  ) {}

  getStore(id: number) {
    return this.connector.query<IGetStore>({
      query: GET_STORE,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  }

  getStores(
    variables: {
      storeNumbers?: number[];
      active?: number;
      search?: string;
    } = {}
  ) {
    return this.connector
      .query<IGetStores>({
        query: GET_STORES,
        variables,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((result) => result.data.getStores),
        map((result) => result.map((store) => new Store(store)))
      );
  }

  createStore(store: Partial<Store>) {
    return this.connector.mutateV2<ICreateStore>({
      mutation: CREATE_STORE,
      variables: { store },
    });
  }

  updateStore(store: Partial<Store>) {
    return this.connector.mutateV2<IUpdateStore>({
      mutation: UPDATE_STORE,
      variables: { store },
    });
  }

  deleteStore(store: Partial<Store>) {
    return this.connector.mutateV2<IDeleteStore>({
      mutation: DELETE_STORE,
      variables: { store },
    });
  }

  getStoreAnalytics(period: string, cache = false) {
    const params: any = cache ? {} : { fetchPolicy: 'network-only' };

    return this.apollo
      .use('omniApi')
      .watchQuery<GetStoreAnalytics>({
        query: GET_STORE_ANALYTICS,
        variables: { period },
        pollInterval: 30000,
        ...params,
      })
      .valueChanges.pipe(map((result) => result.data.getStoreAnalytics));
  }

  getOpenStoreOrders() {
    return this.authService.state$.pipe(
      switchMap((state) =>
        this.apollo
          .use('omniApi')
          .watchQuery<GetOpenStoreOrders>({
            query: GET_OPEN_STORE_ORDERS,
            fetchPolicy: 'network-only',
            variables: { storeNumber: state.storeId },
            pollInterval: 30000,
          })
          .valueChanges.pipe(map((result) => result.data.getOpenStoreOrders))
      )
    );
  }

  getStoreFulfillmentTime(id: number) {
    return this.apollo
      .use('omniApi')
      .watchQuery<GetFulfillmentTime>({
        query: GET_FULFILLMENT_TIME,
        variables: { id },
        fetchPolicy: 'network-only',
        pollInterval: 30000,
      })
      .valueChanges.pipe(map((result) => result.data.getFulfillmentTime));
  }
}
