import { createReducer, on } from '@ngrx/store';
import {
  TrackedQuery,
  addQuery,
  getQueries,
  removeQuery,
} from '../actions/tracker-link.actions';

export const initialState: ReadonlyArray<TrackedQuery> = [];

export const queryReducer = createReducer(
  initialState,
  on(getQueries, (_state, { queries }) => queries),
  on(addQuery, (state, { query }) => [...state, query]),
  on(removeQuery, (state, { id }) => state.filter((s) => s.id !== id))
);
