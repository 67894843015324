import gql from 'graphql-tag';
import { Package, PaginatedPackage } from 'wilco-lib-models';

const PACKAGES_LITE = `
  id
  status
  storeNumber
  packageNumber
  pendingAt
  processedAt
  completedAt
  fulfillmentMethod
  pickupAfter
  _orderId
  createdAt
  order {
    id
    orderNumber
    customerFirstName
    customerLastName
    customerPhone
    customerEmail
    customerAccountNumber
    billingAddress
    shippingAddress
    status
    orderDate
    alternativePickupPerson
  }
  refunds {
    id
    status
  }
  orderItems {
    id
    stagedAt
  }
  fulfillments {
    stagedAt
    picker
    notifiedAt
  }
`;

const PACKAGES = `
  ${PACKAGES_LITE}
  meta
  isFocused
  _orderId
  shippingCharge {
    id
    total
    externalId
    shipmentMeta
  }
  order {
    id
    antiFraud
    orderDate
    createdAt
    completedAt
    externalId
  }
  fulfillments {
    id
    status
    stagedAt
    picker
    processingAt
    notifiedAt
  }
  store {    
    id
    name
    address
    phoneNumber
  }
  orderItems {
    id
    _packageId
    externalId
    itemNumber
    quantity
    quantityFilled
    quantityRefunded
    stagedAt
    unitPrice
    total
    tax
    item {
      masterData
      locationSecondaryData
      image
      upcs
      product {
        images
      }
    }
  }
  refunds {
    id
    _packageId
    externalId
    status
    total
    reason
    initiatedBy
    approvedBy
    shippingItems
    refundAfter
    lineItems
    approvedAt
    completedAt
  }
`;

export const GET_PACKAGE_ID = gql`
  query Package($id: Int, $packageNumber: String) {
    package(id: $id, packageNumber: $packageNumber) {
      id
      status
      packageNumber
    }
  }
`;
export const GET_PACKAGES_BY_STORE_LITE = gql`
  query Packages($params: JSON) {
    packages(params: $params) {
      ${PACKAGES_LITE}
    }
  }
`;

export const GET_PACKAGES_BY_STORE = gql`
  query Packages($params: JSON) {
    packages(params: $params) {
      ${PACKAGES}
    }
  }
`;

export const GET_ACTIONABLE_PACKAGES = gql`
  query GetActionablePackages($storeNumber: Int, $limit: Int, $offset: Int) {
    getActionablePackages(
      storeNumber: $storeNumber
      limit: $limit
      offset: $offset
    ) {
      results {
        id
        packageNumber
        order {
          id
          orderNumber
        }
        refunds {
          id
          status
        }
        store {
          id
          name
        }
      }
      total
    }
  }
`;

export const GET_PACKAGE_BY_ID = gql`
  query Package($id: Int, $packageNumber: String,$storeNumber: Int) {
    package(id: $id, packageNumber: $packageNumber, storeNumber: $storeNumber) {
      ${PACKAGES}
    }
  }
`;

export const GET_PICKLIST = gql`
  query getPicklist($storeNumber: Int!) {
    getPicklist(storeNumber: $storeNumber)
  }
`;

export const UPDATE_PACKAGES = gql`
  mutation UpdatePackages($packages: [PackageInput!]!, $noDelete: Boolean) {
    updatePackages(packages: $packages, noDelete: $noDelete)
  }
`;

export const UPDATE_PACKAGE = gql`
  mutation UpdatePackage($_package: PackageInput!) {
    updatePackage(_package: $_package)
  }
`;

export const UPDATE_STORE_USERS = gql`
  mutation UpdateStoreUsers(
    $type: String!
    $name: String!
    $storeNumber: Int!
  ) {
    updateStoreUsers(type: $type, name: $name, storeNumber: $storeNumber)
  }
`;

export const CREATE_SHIPMENT = gql`
  mutation CreateShipment($payload: ShipmentPayloadInput!) {
    createShipment(payload: $payload)
  }
`;

export const CREATE_CUSTOMER_RECEIPT = gql`
  mutation CreateCustomerReceipt($id: Int!) {
    createCustomerReceipt(id: $id)
  }
`;

export interface ICreateCustomerReceipt {
  createCustomerReceipt: string;
}

export interface IGetPackageParams {
  storeNumber: number;
  picker?: string;
  status?: string;
  packageNumber?: string;
  customerFirstName?: string;
  customerLastName?: string;
  isMC?: boolean;
  isAudit?: boolean;
}

export interface IGetPackage {
  package: Package;
}

export interface IGetPackages {
  packages: Package[];
}

export interface IGetActionablePackages {
  getActionablePackages: PaginatedPackage;
}

export interface IUpdateOrderItems {
  updateOrderItems: string;
}

export interface IGetPicklist {
  getPicklist: string;
}
