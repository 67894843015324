import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  notify(msg: string, action = 'CLOSE', config = { duration: 3000 }) {
    this.snackBar.open(msg, action, config);
  }
}
