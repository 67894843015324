import { Injectable } from '@angular/core';
import { fields } from '@app/modules/admin/pages/admin-prop65/chemical-warning-assoc/chemical-warnings.assoc.data';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Option } from '../models';
import {
  GET_OPTION,
  GET_OPTIONS,
  GET_OPTIONS_V2,
  IGetOptions,
  OptionQuery,
  OptionsQuery,
  UPDATE_OPTION,
  UPDATE_OPTIONS,
} from '../queries/option.graphql';

@Injectable({
  providedIn: 'root',
})
export class OptionService {
  private readonly _refresh$ = new BehaviorSubject<void>(null);
  private readonly refresh$ = this._refresh$.asObservable();

  chemicalMessageTypes$ = this.getOptionsV2(fields).pipe(shareReplay(1));

  constructor(private apollo: Apollo) {}

  getOption(optionKey: string) {
    return this.apollo
      .query<OptionQuery>({
        query: GET_OPTION,
        fetchPolicy: 'network-only',
        variables: {
          optionKey,
        },
      })
      .pipe(map((res) => res.data.option));
  }

  getOptions(optionKeys: string[]) {
    return this.refresh$.pipe(
      switchMap(() =>
        this.apollo
          .query<OptionsQuery>({
            query: GET_OPTIONS,
            fetchPolicy: 'network-only',
            variables: {
              optionKeys,
            },
          })
          .pipe(map((res) => res.data.options || []))
      )
    );
  }
  getOptionsV2(keys: string[], watch = false) {
    const query = watch
      ? this.apollo.use('omniApi').watchQuery<IGetOptions>({
          query: GET_OPTIONS_V2,
          fetchPolicy: 'network-only',
          variables: {
            keys,
          },
          pollInterval: 5000,
        }).valueChanges
      : this.apollo.use('omniApi').query<IGetOptions>({
          query: GET_OPTIONS_V2,
          fetchPolicy: 'network-only',
          variables: {
            keys,
          },
        });

    return query.pipe(map((res) => res.data.getOptions || []));
  }

  updateOption(option: Option) {
    return this.apollo.mutate({
      mutation: UPDATE_OPTION,
      variables: {
        option,
      },
    });
  }

  updateOptions(options: Option[]) {
    return this.apollo
      .mutate({
        mutation: UPDATE_OPTIONS,
        variables: {
          options,
        },
      })
      .pipe(tap(() => this._refresh$.next()));
  }
}
