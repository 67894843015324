import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductBrand, ProductGroup } from '../models';
import {
  DELETE_PRODUCT_BRAND,
  GET_PRODUCT_BRANDS,
  ProductBrandsQuery,
  UPDATE_PRODUCT_BRAND,
} from '../queries/product-brand.graphql';
import { LoggerService } from './logger.service';
import { WooApiService } from './woo-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductBrandService implements OnDestroy {
  private initalizing: Promise<ProductBrand[]>;
  private productBrands: ProductBrand[] = [];

  productBrandsChanged$ = new BehaviorSubject<ProductBrand[]>([]);

  protected onDestroy = new Subject<void>();

  constructor(
    private apollo: Apollo,
    private logger: LoggerService,
    private wooApiService: WooApiService
  ) {}

  initBrands() {
    this.initalizing = this.apollo
      .query<ProductBrandsQuery>({
        query: GET_PRODUCT_BRANDS,
        fetchPolicy: 'network-only',
      })
      .toPromise()
      .then(result => {
        this.productBrands = result.data.productBrands
          .map(brand => {
            // migration from old image format to new, this will need to stay in place until we 100% don't need it anymore
            if (brand.image) {
              const image = brand.image as any;
              if (typeof image.imageSource === 'string') {
                brand.image = {
                  wooId: image.attachmentID,
                  image: image.imageSource,
                  thumbnail: image.imageSource,
                };
              }
            }
            return brand;
          })
          .sort((a, b) => a.brandName.localeCompare(b.brandName));

        this.productBrandsChanged$.next(this.productBrands.slice());

        this.logger.debug(
          'ProductBrandService',
          'productBrands set to',
          this.productBrands
        );
        return this.productBrands;
      });

    return this.initalizing;
  }

  getBrands() {
    if (!this.productBrands || this.productBrands.length === 0) {
      this.logger.debug('ProductBrandService', 'getBrands returned empty');
      return [];
    }
    return this.productBrands.slice();
  }

  async getBrandById(id: number) {
    if (!id) {
      return null;
    }

    await this.initalizing;

    const brand = this.productBrands.find(b => b.id === id);
    return brand ? new ProductBrand(brand) : null;
  }

  getBrandByName(name: string) {
    if (!name) {
      return null;
    }

    const brand = this.productBrands.find(
      b => b.brandName.toLowerCase() === name.toLowerCase()
    );
    return brand ? brand : null;
  }

  getBrandsByName(name: string) {
    if (!name) {
      return this.productBrands;
    }

    const brands = this.productBrands.filter(b =>
      b.brandName.toLowerCase().includes(name.toLowerCase())
    );
    return brands ? brands : this.productBrands;
  }

  updateProductBrand(productBrand: ProductBrand): Promise<ProductBrand> {
    return new Promise(async (resolve, reject) => {
      // send to Woo and get the wooId in response
      const wooId: any = await this.updateWooBrand(productBrand);
      productBrand.wooId = wooId;
      productBrand.featuredProducts = productBrand.featuredProducts.map(
        fp => ({ groupId: fp.groupId } as ProductGroup)
      );
      productBrand.newProducts = productBrand.newProducts.map(
        np => ({ groupId: np.groupId } as ProductGroup)
      );

      console.log(wooId);
      this.apollo
        .mutate({
          mutation: UPDATE_PRODUCT_BRAND,
          variables: {
            productBrand,
          },
          errorPolicy: 'all',
        })
        .pipe(
          map(async (r: any) => {
            if (r.errors && r.errors.length > 0) {
              return r;
            }
            return new ProductBrand(r.data.updateProductBrand);
          })
        )
        .toPromise()
        .then(resp => {
          this.initBrands();
          resolve(resp);
        });
    });
  }

  deleteProductBrand(productBrand: ProductBrand) {
    return new Promise(async (resolve, reject) => {
      await this.deleteWooBrand(productBrand);

      this.apollo
        .mutate({
          mutation: DELETE_PRODUCT_BRAND,
          variables: {
            id: productBrand.id,
          },
          errorPolicy: 'all',
        })
        .toPromise()
        .then(resp => {
          this.initBrands();
          resolve(resp);
        });
    });
  }

  updateWooBrand(productBrand: ProductBrand) {
    return new Promise(async (resolve, reject) => {
      const featuredProducts = productBrand.featuredProducts
        .filter(p => !!p.woosync && !!p.woosync.wooId)
        .map(p => p.woosync.wooId);
      const newProducts = productBrand.newProducts
        .filter(p => !!p.woosync && !!p.woosync.wooId)
        .map(p => p.woosync.wooId);

      const formData = new FormData();
      formData.append('action', 'brand');
      formData.append('name', productBrand.brandName);
      formData.append(
        'image',
        productBrand.image ? productBrand.image.wooId.toString() : null
      );
      formData.append('featuredProducts', JSON.stringify(featuredProducts));
      formData.append('newProducts', JSON.stringify(newProducts));
      formData.append(
        'wooId',
        !!productBrand.wooId ? productBrand.wooId.toString() : ''
      );

      await this.wooApiService
        .makeRequest('product', formData)
        .toPromise()
        .then((res: any) => {
          if (res.error) {
            console.log('error from api', res.error);
            this.logger.error(
              'ProductBrandService',
              'Woo replied with an error',
              res.error
            );
            resolve(null);
          } else {
            console.log('data from api', res.body.data);
            resolve(res.body.data.wooId);
          }
        })
        .catch(err => {
          this.logger.error(
            'ProductBrandService',
            'Woo replied with an error',
            err
          );
          resolve(null);
        });
    });
  }

  deleteWooBrand(productBrand: ProductBrand) {
    return new Promise(async (resolve, reject) => {
      if (!productBrand.wooId) {
        // no wooId, skip
        return resolve(true);
      }

      const formData = new FormData();
      formData.append('action', 'deletebrand');
      formData.append('wooId', productBrand.wooId.toString());

      await this.wooApiService
        .makeRequest('product', formData)
        .toPromise()
        .then((res: any) => {
          if (res.error) {
            this.logger.error(
              'ProductBrandService',
              'Woo replied with an error',
              res.error
            );
            resolve(null);
          } else {
            resolve(res.body.data.success);
          }
        })
        .catch(err => {
          this.logger.error(
            'ProductBrandService',
            'Woo replied with an error',
            err
          );
          resolve(null);
        });
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
