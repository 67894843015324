import gql from 'graphql-tag';
import { Store, StoreAnalytics } from 'wilco-lib-models';
import {
  CreateStoreResponse,
  DeleteStoreResponse,
  UpdateStoreResponse,
} from 'wilco-lib-models/src/domain/stores';

export const GET_STORE = gql`
  query GetStore($id: Int!) {
    getStore(id: $id) {
      id
      name
      ecomId
      address
      metaData
      phoneNumber
      fax
      active
      pickupInstructions
      storeCode
      storeHours
      storeImage
      pickupImage
      shortName
      notifyEmail
    }
  }
`;

export const GET_STORES = gql`
  query getStores($storeNumbers: [Int!], $active: Int, $search: String) {
    getStores(storeNumbers: $storeNumbers, active: $active, search: $search) {
      id
      name
      shortName
    }
  }
`;

export const GET_FULFILLMENT_TIME = gql`
  query GetFulfillmentTime($id: Int!) {
    getFulfillmentTime(id: $id)
  }
`;

export const GET_STORE_ANALYTICS = gql`
  query GetStoreAnalytics($period: String!) {
    getStoreAnalytics(period: $period) {
      id
      name
      fulfilledOrders
      averageFulfillment
    }
  }
`;

export const GET_OPEN_STORE_ORDERS = gql`
  query GetOpenStoreOrders($storeNumber: Int) {
    getOpenStoreOrders(storeNumber: $storeNumber) {
      id
      name
      pending
      customerReady
      needsAttention
    }
  }
`;

export const CREATE_STORE = gql`
  mutation CreateStore($store: StoreInput!) {
    createStore(store: $store) {
      status
      result
      message
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation UpdateStore($store: StoreInput!) {
    updateStore(store: $store) {
      status
      result
      message
    }
  }
`;

export const DELETE_STORE = gql`
  mutation DeleteStore($store: StoreInput!) {
    deleteStore(store: $store) {
      status
      result
      message
    }
  }
`;

export interface IGetStore {
  getStore: Store;
}
export interface IGetStores {
  getStores: Store[];
}
export interface ICreateStore {
  createStore: CreateStoreResponse['result'];
}
export interface IUpdateStore {
  updateStore: UpdateStoreResponse['result'];
}
export interface IDeleteStore {
  deleteStore: DeleteStoreResponse['result'];
}

export interface GetFulfillmentTime {
  getFulfillmentTime: number;
}
export interface GetStoreAnalytics {
  getStoreAnalytics: StoreAnalytics[];
}
export interface GetOpenStoreOrders {
  getOpenStoreOrders: StoreAnalytics;
}
