import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  mobileMainFocused = new BehaviorSubject<boolean>(false);

  constructor() {}

  setMobileMainFocused(focused: boolean) {
    this.mobileMainFocused.next(focused);
  }
}
