import { NgModule } from '@angular/core';
import { TrackedQuery } from '@app/shared/stores/tracker-link/store/actions/tracker-link.actions';
import { RouterReducerState } from '@ngrx/router-store';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import {
  ActivityLog,
  AdItem,
  AdPromotion,
  AdSignage,
  Brand,
  Category,
  Chemical,
  EmailTemplate,
  Fineline,
  IGetProductsConfig,
  Image,
  ItemColor,
  ItemSize,
  Order,
  Package,
  PaginatedResponse,
  Product,
  ProductWarning,
  QuantityBreak,
  SearchAdItem,
  SignCollection,
  SignTemplate,
  Species,
  Store,
  Woosync,
} from 'wilco-lib-models';
import { RouterState, RouterStoreModule } from './router/router-store.module';
import { TrackLinkModule } from './tracker-link/tracker-link.module';

export interface AppState {
  pick: { packages: Package[]; staged: boolean };
  orderStore: { order: Order; refundMode: boolean };
  emailStore: {
    emailTemplate: EmailTemplate;
    emailPreview: string;
    emailTemplateList: EmailTemplate[];
  };
  queries: TrackedQuery[];
  router: RouterReducerState<RouterState>;
  signages: { template: SignTemplate };
  collections: {
    collection: SignCollection;
    labels: { id: number; name: string }[];
  };
  ecomStore: {
    imagesToUpdate?: Image[];
    imagesToDelete?: number[];
    _store: Store;
    store: Store;
    stores: Store[];
  };
  activityStore: {
    activity: ActivityLog[];
  };
  promotionStore: {
    promotionList: AdPromotion[];
    _promotion: AdPromotion;
    promotion: AdPromotion;
    signage: AdSignage;
    createdSignageId: number;
    adItems: PaginatedResponse<AdItem>;
    searchAdItems: SearchAdItem[];
    isUpdating: boolean;
    hasChanges: boolean;
    loader: string;
  };
  productStore: {
    config: IGetProductsConfig;
    productList: Product[];
    _product: Product;
    product: Product;
    _category: Category;
    category: Category;
    _brand: Brand;
    brand: Brand;
    woosyncJob: Woosync;
    quantityBreak: QuantityBreak;
    colors: ItemColor[];
    color: ItemColor;
    _color: ItemColor;
    sizes: ItemSize[];
    size: ItemSize;
    _size: ItemSize;
    productWarnings: ProductWarning[];
    brands: Brand[];
    finelines: Fineline[];
    species: Species[];
    _specie: Species;
    specie: Species; // This one is special because english...
    categories: Category[];
    chemicals: Chemical[];
    hasChanges: boolean;
  };
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ['pick', 'queries'],
    rehydrate: true,
    restoreDates: false, // Preserve my ISO dates please
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
];

@NgModule({
  imports: [RouterStoreModule, TrackLinkModule],
  exports: [RouterStoreModule, TrackLinkModule],
})
export class RootStoreModule {}
