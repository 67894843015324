import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DeleteImage,
  DELETE_S3_OBJECT,
  GetS3Bucket,
  GetS3Object,
  GET_S3_BUCKET,
  GET_S3_OBJECT,
  UploadImage,
  UPLOAD_S3_OBJECT,
} from '@app/core/queries/content/s3-object.graphql';
import { environment } from '@env/environment';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { S3ParamsInput } from 'wilco-lib-models';

@Injectable({ providedIn: 'root' })
export class S3ImageService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  getS3Object(key: string, bucket = 'back40-media') {
    return this.apollo
      .use('omniApi')
      .query<GetS3Object>({
        query: GET_S3_OBJECT,
        variables: { bucket, key },
        fetchPolicy: 'network-only',
      })
      .pipe(map(result => result.data.getS3Object));
  }

  getS3Bucket(key: string, bucket = 'back40-media') {
    return this.apollo
      .use('omniApi')
      .query<GetS3Bucket>({
        query: GET_S3_BUCKET,
        variables: { bucket, key },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(result => result.data.getS3Bucket),
        map(keys => keys.map(key => `${environment.mediaURL}${key}`))
      );
  }

  uploadS3Object({
    key,
    body,
    contentType,
    bucket = 'back40-media',
  }: Partial<S3ParamsInput>) {
    return this.apollo
      .use('omniApi')
      .mutate<UploadImage>({
        mutation: UPLOAD_S3_OBJECT,
        variables: { params: { key, body, contentType, bucket } },
      })
      .pipe(map(result => result.data.uploadImage));
  }

  deleteS3Object(key: string, bucket = 'back40-media') {
    return this.apollo
      .use('omniApi')
      .mutate<DeleteImage>({
        mutation: DELETE_S3_OBJECT,
        variables: { bucket, key },
      })
      .pipe(map(result => result.data.deleteImage));
  }
}
