import { environment } from '@env/environment';

export const auth0Options = {
  container: 'sign-in-form',
  auth: {
    audience: 'https://back40.cloud',
    redirect: false,
    redirectUrl: environment.host + '/auth/login',
    // redirectUrl: 'http://192.168.0.139:4200' + '/auth/login',
    responseType: 'token id_token',
    params: {
      scope: 'openid profile user_metadata',
    },
  },
  theme: {
    logo: '',
    primaryColor: '#1e77b2',
  },
  configurationBaseUrl: 'https://cdn.auth0.com',
  oidcConformant: true,
  autoclose: true,
};

export interface AuthState {
  action: string;
  idToken: string;
  storeId: number;
  permissions: string[];
  userName: string;
  defaultRoute: string;
  isMichael: boolean;
  isSalem: boolean;
  isAuthenticated: Function;
  hasPermission: Function;
}
