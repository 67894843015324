import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { sha256 } from 'js-sha256';

@Injectable({
  providedIn: 'root',
})
export class WooApiService {
  constructor(private http: HttpClient) {}

  makeRequest(endpoint, formData: FormData) {
    const expires = Math.floor(Date.now() / 1000 + 1200);
    const path = '/wp-content/themes/wilco-child/api/v2/' + endpoint + '.php';
    const hash = sha256.hmac(environment.wilcoApiKey, path + expires);

    /**
     * This needs to query through omniapi. Above function will send the
     * query out for now but the results are not returning properly
     */
    return this.http.post(environment.ecomURL + path, formData, {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        'api-key': environment.wilcoApiKey,
        expires: expires.toString(),
        hash,
      }),
    });
  }
}
