import gql from 'graphql-tag';
import { ErrorLog } from '../models';

export const UPDATE_ERROR_LOG = gql`
  mutation UpdateErrorLog($errorLog: ErrorLogInput) {
    updateErrorLog(errorLog: $errorLog) {
      id
      level
      source
      data
      createdAt
    }
  }
`;

export interface ErrorLogQuery {
  errorLog: ErrorLog[];
}
