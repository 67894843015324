import { NgModule } from '@angular/core';
import { Params, RouterStateSnapshot } from '@angular/router';
import {
  NavigationActionTiming,
  RouterReducerState,
  RouterStateSerializer,
  StoreRouterConnectingModule,
  routerReducer,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { ROUTER_FEATURE_KEY } from './actions/router.action';
import { RouterService } from './router.service';

export interface RouterState {
  url: string;
  queryParams: Params;
  params: Params;
  entity?: string;
}

const initialState: RouterReducerState<RouterState> = {
  state: { url: '/', params: {}, queryParams: {} },
  navigationId: -1,
};

export class CustomSerializer implements RouterStateSerializer<RouterState> {
  serialize(routerState: RouterStateSnapshot): RouterState {
    let route = routerState.root;
    let params = {};
    let entity = null;

    while (route.firstChild) {
      entity = route.firstChild.data?.entity ?? entity;

      if (route.firstChild.params) {
        // Aggregate all params on our current route
        params = { ...params, ...route.firstChild.params };
      }
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams, entity };
  }
}

@NgModule({
  providers: [RouterService],
  imports: [
    StoreModule.forFeature(ROUTER_FEATURE_KEY, routerReducer, { initialState }),
    StoreRouterConnectingModule.forRoot({
      stateKey: ROUTER_FEATURE_KEY,
      serializer: CustomSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
  ],
})
export class RouterStoreModule {}
