import { ProductChild } from './product-child.model';

export interface ProductMeta {
  itemNumber: string;
  productsMaster: Object;
  productsLocation: Object;
  productsLocationSecondary: Object;
  productsPim: Object;
  droppedFromFeed: number;
  dateCreated: Object;
  dateUpdated: Object;
  fineline: string;
  productsMasterUpdated: string;
  productsLocationUpdated: string;
  productsLocationSecondaryUpdated: string;
  productsMasterDropped: number;
  productsLocationDropped: number;
  productsLocationSecondaryDropped: number;
  productsVelocity: Object;
  products?: ProductChild[];
}

interface Image {
  alt: string;
  image: string;
}

export interface ProductMetaResults {
  results: ProductMeta[];
  total: number;
}
