import { Injectable } from '@angular/core';
import { QueryOptions } from '@apollo/client/core';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { Apollo } from 'apollo-angular';
import {
  EmptyObject,
  MutationOptions,
  MutationResult,
  ResultOf,
  WatchQueryOptions,
} from 'apollo-angular/types';
import { FetchResult } from 'apollo-link';
import { EMPTY, Observable, of, zip } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { DomainResponse, Notification } from 'wilco-lib-models';
import { ActivityLogService } from './activity-log.service';
import { NotificationService } from './notification.service';

/**
 * This was a thought and while the typings work, I haven't thought up an
 * easy way to centralize the enforcement of it.
 */
export type AtLeastOneElement<T> = [T, ...T[]];

type ConnectorResponse<T> = MutationResult<T> & { operation: string };

function hasAtLeastOneElement<T>(
  arr: T[] | AtLeastOneElement<T>
): arr is AtLeastOneElement<T> {
  return arr.length >= 1;
}

/**
 * This is a wrapper around the Apollo client so that way we can centralize
 * our logging/toast messages for our mutations
 */
@Injectable({ providedIn: 'root' })
export class APIConnector {
  constructor(
    private apollo: Apollo,
    private activityService: ActivityLogService,
    private notificationService: NotificationService
  ) {}

  watchQuery<T, V = ResultOf<any>>(
    options: WatchQueryOptions<V>
  ): Observable<ApolloQueryResult<T>> {
    return this.apollo
      .use('omniApi')
      .watchQuery<T, V>(options)
      .valueChanges.pipe(
        filter((result) => !result.errors)
      ) as unknown as Observable<ApolloQueryResult<T>>;
  }

  query<T, V = EmptyObject>(
    options: QueryOptions<V>
  ): Observable<ApolloQueryResult<T>> {
    return this.apollo
      .use('omniApi')
      .query<T, V>(options)
      .pipe(filter((result) => !result.errors)) as unknown as Observable<
      ApolloQueryResult<T>
    >;
  }

  mutate<T, V = ResultOf<any>>(
    options: MutationOptions<T, V>,
    notifications: Notification[]
  ): Observable<FetchResult<T>> {
    return of(EMPTY).pipe(
      filter(() => this.enforceNotifications(notifications)),
      switchMap(() =>
        this.apollo
          .use('omniApi')
          .mutate<T, V>(options as unknown as MutationOptions<T, V>)
          .pipe(
            map((result) => ({ result, notifications })),
            this.notifyAndLog.bind(this)
          )
      )
    );
  }

  /**
   * All response regardless of query should come through as a
   * DomainResponse object. The implementation of this object can
   * vary from query to query (value in the result field can be
   * whatever you need it to be), however the status should always
   * reflect the state of the request and it should always return
   * a message to the requestor.
   */
  mutateV2<T, V = EmptyObject>(options: MutationOptions<T, V>): Observable<T> {
    return this.apollo
      .use('omniApi')
      .mutate<T, V>(options)
      .pipe(
        // Remove GQL query abstraction
        map(
          (result: ConnectorResponse<T>) =>
            result.data[this._camelize(result.operation)]
        ),
        // Notify our user of success/failure
        tap((response: DomainResponse<T>) => {
          this.notificationService.notify(response.message);
        }),
        // Map the actual response we want
        map((response) => response.result)
      );
  }

  /**
   * This operator is in charge of logging all mutations and notifying the user
   * of response success/failures
   *
   * @param source Source Observable
   * @returns Graphql Response Object
   */
  private notifyAndLog<T>(
    source: Observable<{
      result: ApolloQueryResult<T>;
      notifications: Notification[];
    }>
  ) {
    return source.pipe(
      switchMap(({ result, notifications }) =>
        notifications.length > 0
          ? zip(
              ...notifications.map((notification) =>
                this.activityService.createLog(notification.log)
              )
            ).pipe(
              map(
                () => ({ result, notification: notifications[0] }),
                tap(({ notification }) =>
                  this.notificationService.notify(notification.success)
                )
              )
            )
          : of({ result })
      ),
      catchError(() => {
        return source.pipe(
          tap(({ notifications }) =>
            this.notificationService.notify(notifications[0].error)
          ),
          map(() => ({ result: null }))
        );
      }),
      map(({ result }) => result)
    );
  }

  /**
   * Ensures developers are passing proper notifications. If you must ignore this, comment
   * out the filter in the mutation.
   *
   * Note on enhancements:
   *
   * Thinking about this more... I think notifications are best handled on the backend. That being
   * said we need to pass user data to the backend with every request to do so. This is user data
   * will also be needed for proper auth handling in Omniapi anyways. As part of the Products
   * entity migrations, we should setup proper querying/mutations and have a logging service that
   * logs everything for us.
   *
   * @param notifications
   * @returns
   */
  private enforceNotifications(notifications: Notification[]) {
    return true;
    if (notifications.length >= 1) return true;

    this.notificationService.notify('Please pass at least one notification');
    return false;
  }

  private _camelize(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }
}
