/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import * as dayjs from 'dayjs';
import { map, switchMap } from 'rxjs/operators';
import { EntityTypes, Order } from 'wilco-lib-models';
import { AuthService } from '../auth';
import {
  GET_EXPORT_ORDERS,
  GET_ORDER,
  GET_ORDERS,
  GET_ORDERS_LIST,
  GET_UNASSOCIATED_PACKAGES,
  GET_UNASSOCIATED_REFUNDS,
  IGetExportOrders,
  IGetOrder,
  IGetOrdersList,
  IGetUnassociatedPackages,
  IGetUnassociatedRefunds,
  INTEGRATE_FAILED_ORDERS,
  INTEGRATE_ORDER,
} from '../queries/order/order.graphql';
import { GET_PICKLIST, IGetPicklist } from '../queries/order/packages.graphql';
import {
  INTEGRATE_FAILED_REFUNDS,
  INTEGRATE_REFUND,
} from '../queries/order/refund.graphql';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  searchTerm: string;

  constructor(
    private apollo: Apollo,
    private authService: AuthService
  ) {}

  getOrders(params: any, packages = false, pollInterval = 5000) {
    const query = packages ? GET_ORDERS : GET_ORDERS_LIST;

    return this.authService.state$.pipe(
      switchMap((state) =>
        this.apollo
          .use('omniApi')
          .watchQuery<IGetOrdersList>({
            query,
            variables: {
              params: {
                ...params,
                storeNumbers: state.storeId
                  ? [state.storeId]
                  : params?.storeNumbers,
                limit: 1000,
              },
            },
            fetchPolicy: 'network-only',
            pollInterval,
          })
          .valueChanges.pipe(
            map((result) => result.data.orders.map((o) => new Order(o)))
          )
      )
    );
  }

  getOrder(id: number) {
    return this.authService.state$.pipe(
      switchMap((state) =>
        this.apollo
          .use('omniApi')
          .watchQuery<IGetOrder>({
            query: GET_ORDER,
            variables: { id, storeNumber: state.storeId },
            fetchPolicy: 'network-only',
            pollInterval: 5000,
          })
          .valueChanges.pipe(map((result) => new Order(result.data.order)))
      )
    );
  }

  getExportOrders(ids: number[]) {
    return this.apollo
      .use('omniApi')
      .query<IGetExportOrders>({
        query: GET_EXPORT_ORDERS,
        variables: { ids },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((result) => result.data.getExportOrders),
        map((results) =>
          results.map((res) => {
            const { orderNumber, customerName, orderDate, orderTotal, docId } =
              res;
            return {
              orderNumber,
              customerName,
              orderDate: dayjs(orderDate).format('MM/DD/YYYY HH:mm:ss'),
              orderTotal,
              docId,
            };
          })
        )
      );
  }

  getPicklist(storeNumber: number) {
    return this.apollo
      .use('omniApi')
      .query<IGetPicklist>({
        variables: { storeNumber },
        query: GET_PICKLIST,
      })
      .pipe(map((result) => result.data.getPicklist));
  }

  getOrderItemTitle(item: any) {
    let title =
      item.product && item.product.parent ? item.product.parent.title : '';
    if (!title && item.productMeta.productsMaster) {
      title = item.productMeta.productsMaster['Item Description'];
    }

    return title;
  }

  getStatusColor(value: { label?: string; status: string }) {
    switch (value.status) {
      case 'pending':
        return 'secondary';
      case 'processing':
        return 'warning';
      case 'processed':
        return value.label && value.label === 'Pickup Ready'
          ? 'info'
          : 'violet';
      case 'completed':
        return 'success';
      default:
        return 'danger';
    }
  }

  getUnassociatedPackages({
    pageNumber,
    size,
  }: {
    pageNumber: number;
    size: number;
  }) {
    return this.apollo
      .use('omniApi')
      .query<IGetUnassociatedPackages>({
        variables: { pageNumber, size },
        query: GET_UNASSOCIATED_PACKAGES,
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data.getUnassociatedPackages));
  }

  getUnassociatedRefunds({
    pageNumber,
    size,
  }: {
    pageNumber: number;
    size: number;
  }) {
    return this.apollo
      .use('omniApi')
      .query<IGetUnassociatedRefunds>({
        variables: { pageNumber, size },
        query: GET_UNASSOCIATED_REFUNDS,
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data.getUnassociatedRefunds));
  }

  // syncOrders() {
  //   return this.apollo.use('omniApi').mutate<any>({
  //     mutation: SYNC_ORDERS,
  //   });
  // }

  // syncEcomOrders() {
  //   return this.apollo.use('omniApi').mutate<any>({
  //     mutation: SYNC_ECOM_ORDERS,
  //   });
  // }

  // syncLegacyStatus() {
  //   return this.apollo.use('omniApi').mutate<any>({
  //     mutation: SYNC_LEGACY_STATUS,
  //   });
  // }

  integrate(entity: EntityTypes, id: number) {
    const mutation =
      entity === EntityTypes.PACKAGE ? INTEGRATE_ORDER : INTEGRATE_REFUND;

    return this.apollo.use('omniApi').mutate<any>({
      mutation,
      variables: { id },
    });
  }

  integrateAll(entity: EntityTypes) {
    const mutation =
      entity === EntityTypes.PACKAGE
        ? INTEGRATE_FAILED_ORDERS
        : INTEGRATE_FAILED_REFUNDS;

    return this.apollo.use('omniApi').mutate<any>({
      mutation,
    });
  }
}
