import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductSpecies } from '../models/product-species.model';
import {
  GET_PRODUCT_SPECIES,
  ProductSpeciesQuery,
} from '../queries/product-species.graphql';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class ProductSpeciesService implements OnDestroy {
  private productSpecies: ProductSpecies[];

  productSpeciesChanged$ = new BehaviorSubject<ProductSpecies[]>([]);

  protected onDestroy = new Subject<void>();

  constructor(
    private apollo: Apollo,
    private logger: LoggerService
  ) {}

  initSpecies() {
    this.apollo
      .query<ProductSpeciesQuery>({
        query: GET_PRODUCT_SPECIES,
      })
      .pipe(takeUntil(this.onDestroy))
      .subscribe((result) => {
        this.productSpecies = result.data.productSpecies;
        this.productSpeciesChanged$.next(this.productSpecies.slice());

        this.logger.debug(
          'ProductSpeciesService',
          'productSpecies set to',
          this.productSpecies
        );
      });
  }

  getSpecies() {
    this.productSpecies.slice();
  }

  getSpeciesByName(name: string) {
    if (!name) {
      return null;
    }

    const species = this.productSpecies.find((s) => s.speciesName === name);
    return species ? species : null;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
