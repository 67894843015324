import gql from 'graphql-tag';
import { AuthUser } from 'wilco-lib-models';

export const GET_USERS = gql`
  query GetUsers($name: String) {
    getUsers(name: $name) {
      id
      name
      userName
      nickname
      email
      icon
    }
  }
`;

export interface IGetAuthUsers {
  getUsers: AuthUser[];
}
